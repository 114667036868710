* {
    margin: 0;
    padding: 0;
}

.transcript-get-container{
    margin-top: 71px;
}
.live-get-text {
    display: inline-flex;
    padding: 7px 0px 6px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
}

.live-get-text1 {
    color: #00A8CD;
    /* font-family: Roboto; */
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.live-get-text2 {
    color: #00A8CD;
    /* font-family: Roboto; */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.live-get-btn {
    display: inline-flex;
    height: 56px;
    padding: 2px 56px 4px 56px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    margin-top: 28px;

    border-radius: 14px;
    background: #00A8CD;
    box-shadow: 0px 6px 20px 0px rgba(4, 100, 129, 0.30);

    color: #FFF;
    /* font-family: Poppins; */
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    transition: all 0.3s;
}
.live-get-btn:hover{
    background: #00687E;
}
.live-get-img{
    position: relative;
}

.get-img1{
    position: absolute !important;
    bottom: 40%;
    right: 10%;
}
.get-img2{
    position: absolute;
    bottom: -4%;
    right: 20%;
    width: 134.32px;
}
.get-img3{
    position: relative;
    bottom: 5%;
    max-width: 450px;
}

@media screen and (max-width: 1200px){
    .transcript-get-container{
        margin-top: 7vw;
    }
    .live-get-text {
        padding: 7px 0px 6px 0px;
    }
    
    .live-get-text1 {
        font-size: 3.5vw;
    }
    .live-get-text2 {
        font-size: 2.1vw;
    }
    .live-get-btn {
        height: 56px;
        padding: 2px 7vw 4px 7vw;
        font-size: 2.2vw;
        margin-top: 2vw;
    }    
    .get-img1{
        bottom: 16vw;
        right: 4vw;
        width: 8vw;
    }
    .get-img2{
        bottom: -2vw;
        right: 8vw;
        width: 12vw;
    }
    .get-img3{
        position: relative;
        bottom: 2vw;
        width: 45vw;
    }
}
@media screen and (max-width: 992px){
    .get-img1{
        bottom: 18vw;
        right: 6vw;
        width: 10vw;
    }
    .get-img2{
        bottom: -2vw;
        right: 9vw;
        width: 14vw;
    }
    .get-img3{
        position: relative;
        bottom: 2vw;
        width: 45vw;
    }
}

@media screen and (max-width: 768px){
    .live-get-btn {
        height: 8vw;
        padding: 1vw 5vw;
        font-size: 3vw;
    }
}