* {
    margin: 0;
    padding: 0;
}

.OurCulture-main {
    gap: 150px;
    margin-top: 102px;
}

.OurCulture-main-container {
    gap: 80px;
}

.OurCulture-container {
    gap: 69px;
    padding: 0;
}

.OurCulture-text h4 {
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.48px;
    text-transform: uppercase;
}

.OurCulture-text h2 {
    color: var(--high, #095182);
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.OurCulture-text p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
    padding: 0 13%;
}

.OurCulture-imageContainer {
    /* gap: 33px; */
    width: auto;
}

.OurCulture-imageContainer p {
    text-align: center;

    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.OurCulture-comma {
    width: 927px;
    height: 197px;
    margin: auto;
    border: 1px solid var(--med, #03A6CA);
    background: #FFF;
    box-shadow: 25px 25px 0px 0px #03A6CA;
    border-radius: 25px;
    gap: 85px;
    padding: 30px;
    position: relative;
}

.OurCulture-Map {
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
}

.steve_commaImg {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.steve_commaText {
    width: 627px;
}

.steve_commaText h4 {
    color: var(--Grey, #565565);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 45.5px;
}

.steve_commaText p {
    text-align: end;

    color: var(--Grey, #565565);
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 45.5px;
}

.steve_comma1 {
    margin-bottom: 45px;
    width: 41.04px !important;
}

.steve_comma2 {
    /* position: absolute; */
    /* top: 45px; */
    /* left: 30px; */
    margin-top: 45px;
    margin-left: -10px;
    transform: rotate(180deg);
    width: 41.04px;
}

.steveBox-Tringle {
    width: 45.447px;
    height: 62.975px;
    position: absolute;
    bottom: -43px;
    right: -28px;
}


@media screen and (max-width: 1400px) {
    .OurCulture-main-container {
        /* gap: 2vw; */
    }

    .OurCulture-container {
        gap: 5vw;
    }

    .OurCulture-text h4 {
        /* font-size: 1.2vw; */
        letter-spacing: 2.48px;
    }

    .OurCulture-text h2 {
        /* font-size: 3.2vw; */
        text-align: center;
    }

    .OurCulture-text p {
        /* font-size: 1.2vw !important; */
        margin-top: 12px;
    }

    .OurCulture-imageContainer {
        gap: 2.3vw;
    }




    .OurCulture-comma {
        width: 68vw;
        height: 14vw;
        border: 1px solid var(--med, #03A6CA);
        box-shadow: 2vw 2vw 0px 0px #03A6CA;
        gap: 6vw;
    }

    .steve_commaText {
        width: 627px;
    }

    .steve_commaText h4 {
        /* font-size: 1.6vw; */
        padding-right: 2vw;
        line-height: 3vw;
    }

    .steve_commaText p {
        /* font-size: 1.2vw !important; */
        padding-right: 2vw;
    }

    .steve_comma1,
    .steve_comma2 {
        width: 3vw !important;
    }

    .OurCulture-main {
        gap: 14vw;
        /* margin-top: 7.6vw; */
    }




}

@media screen and (max-width: 768px) {
    .OurCulture-main-container {
        /* gap: 8vw; */
    }

    .OurCulture-container {
        gap: 5vw;
        /* display: grid !important;
        grid-template-columns: auto auto; */
    }


    .OurCulture-imageContainer {
        gap: 2.3vw;
        text-align: center;
    }



    .OurCulture-comma {
        display: none !important;
    }

}


@media screen and (max-width: 576px) {
    .OurCulture-text h4 {
        font-size: 16px !important;
    }

    .OurCulture-text h2 {
        font-size: 36px !important;
    }

    .OurCulture-text p {
        font-size: 16px !important;
        padding: 0;
    }

    .OurCulture-container {
        gap: 10vw !important;
    }

}


/* chetan */
.OurCulture-parent {
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
    width: 263px;
}

/* .OurCulture-parent:hover{
    background-color: rgba(3, 166, 202, 1);
} */
.OurCulture-Freedom {
    gap: 33px;
    /* flex-wrap: wrap; */
}

.OurCulture-parent p {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 33px;
}

@media screen and (max-width: 1200px) {
    .OurCulture-parent {
        border: 1px solid #E4E4E4;
        background: #FFF;
        box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
        width: 21vw;
        padding: 2px;
    }

    .OurCulture-Freedom {
        gap: 33px;
    }

    .OurCulture-parent p {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-top: 3vw;
    }
}

@media screen and (max-width: 992px) {
    .OurCulture-parent {
        border: 1px solid #E4E4E4;
        background: #FFF;
        box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
        width: 263px;
    }

    .OurCulture-Freedom {
        gap: 33px;
        display: grid !important;
        grid-template-columns: auto auto;
    }

    .OurCulture-parent p {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-top: 3vw;
    }
}

@media screen and (max-width: 576px) {
    .OurCulture-parent {
        border: 1px solid #E4E4E4;
        background: #FFF;
        box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
        width: 60vw;
    }

    .OurCulture-Freedom {
        gap: 33px;
        display: grid !important;
        grid-template-columns: auto;
    }

    .OurCulture-parent p {
        color: #000;
        text-align: center;
        /* font-size: 12px !important; */
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-top: 3vw;
    }
}