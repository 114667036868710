img {
  max-width: 100%;
}
.btn-primary {
  background: rgb(191, 235, 250) !important;
  background: linear-gradient(
    90deg,
    rgba(191, 235, 250, 1) 0%,
    rgba(159, 209, 230, 1) 0%,
    rgba(107, 166, 197, 1) 0%,
    rgba(77, 142, 178, 1) 0%,
    rgba(0, 80, 130, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}
.btn {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  min-width: 120px;
  min-height: 40px;
  border-radius: 25px 5px 25px 5px;
  border: none;
}
.btn-outline-primary {
  border: 2px solid #005082;
  color: #005082;
}
.btn-outline-primary:hover {
  border: 2px solid #005082 !important;
  background: linear-gradient( 90deg, rgba(191, 235, 250, 1) 0%, rgba(159, 209, 230, 1) 0%, rgba(107, 166, 197, 1) 0%, rgba(77, 142, 178, 1) 0%, rgba(0, 80, 130, 1) 100%, rgba(0, 212, 255, 1) 100% ) !important ;
  color: white !important;
}

.btn-outline-primary-toggle {
  border: 2px solid #005082;
  background: linear-gradient(
    90deg,
    rgba(191, 235, 250, 1) 0%,
    rgba(159, 209, 230, 1) 0%,
    rgba(107, 166, 197, 1) 0%,
    rgba(77, 142, 178, 1) 0%,
    rgba(0, 80, 130, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
  color: white;
}

/* font weight */
.font-w-600 {
  font-weight: 600 !important;
}
.font-w-700 {
  font-weight: 700 !important;
}
.font-w-500 {
  font-weight: 500 !important;
}
.font-w-400 {
  font-weight: 400 !important;
}
.font-w-800 {
  font-weight: 800 !important;
}

/* font size */
.font-s-12 {
  font-size: 12px !important;
}
.font-s-14 {
  font-size: 14px !important;
}
.font-s-16 {
  font-size: 16px !important;
}
.font-s-18 {
  font-size: 18px !important;
}
.font-s-19 {
  font-size: 19px !important;
}
.font-s-20 {
  font-size: 20px !important;
}
.font-s-22 {
  font-size: 22px !important;
}
.font-s-24 {
  font-size: 24px !important;
}
.font-s-26 {
  font-size: 26px !important;
}
.font-s-40 {
  font-size: 40px !important;
}
.font-s-50 {
  font-size: 50px !important;
}

/* line Height */
.line-height-150 {
  line-height: 1.5 !important;
}
.line-height-140 {
  line-height: 1.4 !important;
}
.line-height-130 {
  line-height: 1.3 !important;
}
.line-height-120 {
  line-height: 1.2 !important;
}

/* Background color */
.bg-0 {
  background-color: whitesmoke;
}
.bg-1 {
  background-color: #005082;
}
.bg-2 {
  background-color: #bfebfa;
}

/* Custom color */
/* color */
.text-lightgrey {
  color: #525252;
}
.text-color-0 {
  color: #017c96;
}
.text-1 {
  color: #005082;
}
.text-2 {
  color: #bfebfa;
}
