.look-more{
    font-size: 20px;
    margin: auto;
    color: #005082;
    font-weight: 600;
}
.fa-sharp{
    color: #005082; 
}
.fa-sharp:hover{
   color: #0c71b1;;
}