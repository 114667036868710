.LakeHome--Wrapper-000-0 {
  background: url("../../../../images/banner/NLPServiceBG.webp");
  padding-top: 20px;
  height:  100vh;
  padding-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}

.Lake-Case-Carousel-Container {
  min-height: 50vh;
  display: flex !important;
  justify-content: space-between;
}

.banner_content-service {
  z-index: 99;
  padding: 40px;
  text-align: left;
  color: #000;
  /* max-width: 55%; */
  background: #ffffff99;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 25px;
  box-shadow: 2px 2px 10px 2px #00000020;
}

.LakeHome-CaseS-Heading h1 {
  color: #005082;
  font-weight: 800;
}

@media only screen and (max-width: 1200px) {
  .LakeHome-CaseS-Heading h1 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 992px) {
  .LakeHome-CaseS-Heading h1 {
    font-size: 24px;
    line-height: unset;
  }
}
@media only screen and (max-width: 767px) {
  .Lake-Case-Carousel-Container {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .LakeHome-CaseS-Heading h1 {
    font-size: 32px;
  }
  .Lake-Case-Carousel-image {
    margin-top: 20px;
    z-index: 100 !important;
  }

  .LakeHome-CaseS-Heading h4 {
    font-size: 14px;
  }
  .lakeH-banner-nlp {
    display: none !important;
  }
}
@media only screen and (max-width: 556px) {
  .LakeHome-CaseS-Heading h1 {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 375px) {
  .LakeHome-CaseS-Heading h4 {
    font-size: 12px;
  }
}


.NLP-Btn{
    font-weight: 500;
    border-radius: 25px 5px 25px 5px;
    border: 2px solid #005082;
    background:#005082;
    padding: 6px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    transition: 0.3s ease-in-out;
    color: white;
}
.NLP-Btn:hover{
  box-shadow:5px 5px 5px 0px #005082;
}