* {
  margin: 0;
  padding: 0;
}

.live-transcript-header {
  padding: 60px 0 108px 0;
}

.navIcon {
  width: 295px;
}

.navBtn {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.transcript-header-btn-1 {
  display: flex;
  height: 56px;
  padding: 21px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 14px;
  border: 2px solid #00A8CD;
  background: #00A8CD;
  box-shadow: 0px 6px 20px 0px rgba(4, 100, 129, 0.30);

  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  transition: all 0.3s;
  line-height: 20px;
}

.transcript-header-btn-1:hover {
  border: 2px solid #00687E;
  background: #00687E;
}

.transcript-header-btn-2 {
  display: flex;
  padding: 16px 29px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 14px;
  border: 2px solid #00A8CD;

  color: #00A8CD;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  transition: all 0.3s;
}

.transcript-header-btn-2:hover {
  border-radius: 14px;
  border: 2px solid #00A8CD;
  background: #00A8CD;
  color: white;
}

@media screen and (max-width: 992px) {

  .live-transcript-header {
    padding: 8vw 15px 10vw 15px !important;
  }

  .navIcon {
    max-width: 295px;
  }

  .transcript-header-btn-1 {
    padding: 2vw 6vw;
    font-size: 2.2vw;
  }

  .transcript-header-btn-2 {
    padding: 1vw 5vw;
    font-size: 2.2vw;
  }
}

@media screen and (max-width: 768px) {

  .live-transcript-header {
    padding: 8vw 15px 10vw 15px !important;
  }

  .navIcon {
    max-width: 295px;
  }

  .transcript-header-btn-1 {
    display: none;
  }

  .transcript-header-btn-2 {
    padding: 1vw 5vw;
    font-size: 3.4vw;
  }
}

@media screen and (max-width: 576px) {

  .navIcon {
    /* max-width: 295px; */
    width: 50%;
    height: 50%;
  }

  .logoImg {
    height: 100%;
    width: 100%;
  }

  .transcript-header-btn-1 {
    display: none;
  }

  .transcript-header-btn-2 {
    padding: 1vw 5vw;
    font-size: 3.4vw;
  }
}