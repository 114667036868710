.blog-card-01 {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  /* overflow: hidden; */
  width: 300px;
  margin: auto;
}
.blog-card-header-01 img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.blog-card-body-01 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.blog-tag-01 {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.blog-tag-teal-01 {
  background-color: #47bcd4;
}
.blog-tag-purple-01 {
  background-color: #5e76bf;
}
.blog-tag-pink-01 {
  background-color: #cd5b9f;
}

.blog-card-body-01 p {
  font-size: 13px;
  margin: 0 0 40px;
}
.blog-user-01 {
  display: flex;
  margin-top: auto;
}

.blog-user-01 img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.blog-user-info-01 h5 {
  margin: 0;
}
.blog-user-info-01 small {
  color: #545d7a;
}
.blog-BlogReadMore-btn-01 {
  background-color: #47bcd4;
  min-width: 110px;
  min-height: 35px;
  transition: 0.3s all ease-in-out;
}
.blog-BlogReadMore-btn-01:hover {
  /* background-color: #47bcd4; */
  text-decoration: none !important;
  /* color: grey; */
  color: black;
  background-color: white;
  box-shadow: 0px 0px 7px 1px #47bcd4;
}
