.mainText {
    background-color: white;
    padding: 20px;
    border: 1px solid #808080;
    border-radius: 25px;
    margin: 50px auto;
    overflow: hidden;
}

.mainTextInput {
    background-color: white;
    padding: 0px;
    border: 1px solid #808080;
    border-radius: 25px;
    width: 100%;
    overflow-y: scroll;
    align-items: center;
}

.textLeft {
    border: 1px solid #02AACE ;
    border-radius: 25px;
    padding: 10px;
    width: 80%;
    float: left;
    margin: 20px 0px;
}

.textRight {
    border: 1px solid #02AACE ;
    border-radius: 25px;
    padding: 10px;
    width: 80%;
    float: right;
    margin: 20px 0px;

}

.questionPdf {
    color: gray;
    font-size: medium;
    line-height: 30px;
}

.questionPdf:hover {
    color: black;
}
#loader_empthy_chatGpt{
    display: none;
    position: fixed;
    top: 50%;
    left: 0%;
    width: 100%;
    justify-content: center;
}