.footermain {
    /* max-width: 1395px; */
    align-items: center;
    /* margin: 3rem auto; */
    height: 100px;
    padding: 50px;
  }

  .footer-wrapper {
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
    padding: 40px 75px;
  }
  
  .footer-section-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
  
  }
  
  .footer-section-two {
    font-family: "Mullish";
    font-size: 18px;
    margin-right: 23px;
    letter-spacing: 0.2;
  }
  
  .logoImg2 {
    width: 65%;
  }
  
  /* .ffi {
    width: 100%;
    height: auto;
  } */
  
  @media (max-width: 1200px) {
    .footer-section-two {
      font-family: "Mullish";
      font-size: 1.8vw;
      margin-right: 0;
    }
    /* .footermain {
      margin: 3vw auto;
    } */
  }
  
  @media (max-width: 768px) {
    .footer-section-one {
      margin-left: 2vw;
    }
  }