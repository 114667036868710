/* NLP start form here */

.npl-wrapper-0-01 {
    background-color: #bfebfa;
    /* background-image: url("../../images//SubPages/NLP/HeaderBackgroundImage.webp"); */
    /* padding: 100px 0px; */
    position: relative;
    padding-bottom: 60px !important;
  }
  
  .services-read-more-00-01 {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  /***************/
  .services-read-more-00-02{
    position: absolute;
    bottom: 20px;
  
  }
  
  
  .NPL-build-next-0 {
    background-color: #005082;
    color: white;
  }
  .NLP-header-btn {
    background-color: #005082 !important;
    color: white !important;
  }
  .NLP-header-btn1 {
    background-color: #bfebfa !important;
    color: black !important;
    font-weight: 600;
  }
  
  .NPL-contanier-00 {
    border-radius: 20px;
    position: relative;
    min-height: 390px;
    background-color: white;
    box-shadow: 2px 4px 4px 0px #0000000f;
    padding-bottom: 60px !important;
  }
  
  .lakeH-NLP-behavior-main {
    bottom: 0;
    position: absolute;
    width: 90%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  
  .npl-behavior-00 {
    background-color: transparent;
    /* padding: 100px 0px; */
  }
  
  .lakeH-NLP-behavior-case-items {
    background-color: #bfebfa;
    color: black;
    padding: 8px 0px;
    border-radius: 20px;
    box-shadow: 2px 4px 4px 0px #0000000f;
  }
  .lakeH-NLP-behavior-items-description {
    padding-left: 100px !important;
  }
  .lakeH-NLP-behavior-title-border {
    border: 2px solid #005082;
    width: 95px;
    border-radius: 34px;
    /* margin-top: 12px; */
    /* margin-left: 5px; */
  }
  
  .text-greypara {
    color: black;
  }
  /* NLP end  here */
  
  /* About us css start from here  */
  .about-header-0 {
    /* background-image: url("../../images/AboutUs//aboutheaderbackground.webp"); */
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2% 7% 1% 7%;
    background-position: center;
  }
  .about-header-0 .about-header-2 {
    /* padding: 12px 54px; */
    padding: 4% 4% 4% 4%;
    /* background-image: url("../../images/AboutUs/aboutusHeader2.webp"); */
    /* background-color: url("../../images//AboutUs/aboutusHeader2.webp"); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 315px;
  }
  
  .about-header-2 h1 {
    font-size: 50px;
  }
  
  .ab-us-head-0 {
    font-size: 26px;
    font-weight: 500;
    color: #017c96;
    line-height: 34px;
    text-decoration: underline;
  }
  .about-header-2 p {
    font-size: 26px;
    font-weight: 400;
    color: #017c96;
    line-height: 34px;
    padding-top: 28px;
    /* text-decoration: underline; */
  }
  .about-header-0 div img {
    max-height: 315px;
  }
  .our-v-para {
    /* width: 60% !important; */
    margin-left: 74px;
    box-shadow: 0px 0px 20px 3px #aba7a7;
    border-radius: 7px;
    border: none;
  }
  
  .lakeH-our-values {
    box-shadow: 0px 0px 20px 3px #aba7a7;
    border: none;
    border-radius: 8px;
    position: relative;
    margin: 0px 12px;
  }
  .lakeH-our-values-img {
    width: 100px;
    padding: 33px;
    border-radius: 510px;
    margin-top: -63px;
    background: #ffa41b;
    color: white !important;
  }
  
  .lakeH-NLP-behavior-img {
    width: 80px;
    height: 80px;
    transition: all 0.3s;
    border-radius: 50%;
    padding: 4px;
  }
  .lakeH-NLP-behavior-img img {
    width: 75px;
    margin: 0 auto 16px;
    border-radius: 50px;
    box-shadow: 4px 4px 10px 4px #bfebfa50;
    padding: 8px;
    border: 1px solid #bfebfa;
    transition: all 0.3s;
  }
  .lakeH-NLP-behavior-img:hover img {
    box-shadow: 1px 1px 10px 1px #00508220;
    border: 1px solid #00508220;
    background-color: rgb(191, 235, 250, 0.3);
    cursor: pointer;
  }
  
  .lakeH-our-values-img img {
    color: white !important;
    height: 27px;
  }
  
  .lakeH-team-0 {
    background-color: whitesmoke;
    box-shadow: 0px 0px 20px 3px #aba7a7;
    border-radius: 10px;
    margin: 10px;
  }
  
  .join-button {
    color: white !important;
    border: none !important;
    border-radius: 20px !important;
    background-color: #ffa41b !important;
    padding: 12px 35px !important;
    border: 1px solid transparent !important;
    margin-top: 12px;
  }
  .join-button:hover {
    background-color: white !important;
    color: black !important;
    border: 1px solid #ffa41b !important;
    font-weight: 500;
  }
  .lakeH-NLP-behavior-case-items {
    text-align: left;
  }
  
  /* About us css end here  */
  


  .main-container{
    height: fit-content;
  }
  
  
  /* About us css end here  */
  @media only screen and (max-width: 992px){
    .NPL-contanier-00{
      min-height: fit-content !important;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .lakeH-NLP-behavior-items-description {
      padding-left: 0px !important;
      padding-right: 0px !important;
      text-align: center;
      font-size: 12px !important;
    }
    .lakeH-NLP-behavior-items-description p {
      font-size: 12px !important;
    }
    .lakeH-NLP-behavior-img img {
      width: 48px;
    }
    .NPL-contanier-00-01 {
      padding: 0px !important;
    }
    .lakeH-NLP-behavior-title {
      font-size: 20px !important;
    }
    .lakeH-NLP-behavior-case-items div {
      text-align: center;
      padding: 0px;
  
      margin: 0px !important;
      justify-content: center;
    }
    .lakeH-NLP-behavior-case-items {
      text-align: center;
    }
    .NPL-contanier-00 {
      padding: 12px !important;
    }
    .nlp-Whyu-HeadingWrappper h2 {
      color: #005082;
    }
    /* .NPL-contanier-00-01 h4 {
      padding: 0px !important;
    } */
    .NPL-contanier-00 {
      border-radius: 20px;
      position: relative;
      min-height: max-content;
      padding-bottom: 60px !important;
      background-color: white;
      box-shadow: 0px 0px 12px 2px #0000000f;
    }
    .lakeH-NLP-behavior-title-border {
      margin: 0px !important;
      border: 1px solid #005082;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .NPL-contanier-00-01 p {
      font-size: 12px !important;
    }
    .NPL-contanier-00-01 h4 {
      font-size: 18px !important;
      padding: 0px !important;
    }
  }
  
  .Read-more-btn{
    background: linear-gradient( 90deg, rgba(191, 235, 250, 1) 0%, rgba(159, 209, 230, 1) 0%, rgba(107, 166, 197, 1) 0%, rgba(77, 142, 178, 1) 0%, rgba(0, 80, 130, 1) 100%, rgba(0, 212, 255, 1) 100% ) !important;
    border: 1px solid #0071e0;
    color: #fff !important;
    outline: none;
     font-size: 12px;
    border-radius: 25px 5px 25px 5px;
    padding: 8px;
  }
  .Read-more-btn:hover{
    /* color: #005082!important;
    background: white !important; */
    /* border: 2px solid #005082; */
    box-shadow: 5px 5px 5px 0px #005082;
    /* font-weight: 600; */
  }
  
  .read-More{
    font-size: 18px;
    color: #005082;
    text-shadow:1px 1px 1px #017c96;
  }
  .read-More:hover{
   color:#017c96;
  }
  