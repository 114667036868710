.blogs-main {
    display: flex;
    /* flex-direction: column; */
    /* margin-top: 40px; */
    /* width: 80%; */
    /* margin: 40px auto auto 60px; */
}

.blogs-h {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.blog-c2 {
    margin-left: 50px;
    display: none;
}

.blog-card-btn {
    margin: 20px 10px 20px 0;
    /* padding: 5px 5px; */
    height: 33px !important;
    font-weight: 500;
    width: 120px !important;
    border-radius: 15px;
    gap: 10px;
    background: rgba(3, 166, 202, 1) !important;
    color: black;
}
.blog-h{
    color: black;
}
.blog-h2 {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato', sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 58px;
    text-align: center;
    width: 80%;
    margin-bottom: 20px;
}

.blog-h22 {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
}

.read-more{
    font-family: 'Lato',sans-serif;
font-size: 14px;
font-style: italic;
font-weight: 400;
color: rgba(9, 81, 130, 1);
line-height: 17px;
letter-spacing: 0.02em;
text-align: left;
padding: 10px 0;
text-decoration: underline rgba(9, 81, 130, 1) solid 1px;

}

.read{
    color: rgba(9, 81, 130, 1) !important;
    margin: 10px 0;
}
.blog-p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    /* text-align: center; */
    width: 80% !important;
    color: rgba(51, 51, 51, 1);
}

.blog-t {
    background-color: white;
    padding: 0 30px 10px 30px;
}

.blog-h2 {
    font-family: 'Lato', sans-serif;
    font-size: 42px;
    font-weight: 900;
    line-height: 58px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(9, 81, 130, 1);
}

.blog-cards {
    display: flex;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 550px;
    height: auto;

    /* padding-left: 55px;
    padding-right: 55px; */
}

.backg {
    width: 65%;
}

.blog-btns {
    display: flex;
    justify-content: center;
    margin-left: 100px;
    gap: 10px;
}

.blog-texts {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.blog-img{
    width: 100%;
}

.blog-c{
    
    background-color: white;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */

}



@media screen and (max-width: 992px) {
    .blogs-main{
        flex-direction: column;
        align-items: center;
    }
    .blog-img{
        width: 100%;
    }
    .blog-texts{
        align-items: center;
    }

    .blog-cards{
        /* margin: auto; */
        margin-top: 50px;
    }

.blog-p{
    width: 100% !important;
    text-align: center;
}

.blog-h2{
    width: 100% !important;
}

}


@media screen and (max-width:600px) {
    .blogs-main {
        flex-direction: column;
        margin: 0px;
    }

    .blog-p2{
        font-size: 12px !important;
    }

    .blog-btns {
        margin: 20px 0 40px 0;
    }
   .blog-cards{
    width: auto;
    height: auto;
    margin-top: 0;
   }
    .blog-cards {
        width: 100%;
    }
    .blog-t{
        padding: 0px 10px;
    }
    .blog-h {
        font-size: 15px;
        line-height: 12px;
        margin: 15px 0;
        font-weight: 500;
    }

    .blog-h2 {
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 15px;
    }

    .blog-p {
        font-size: 12px !important;
        line-height: 18px;
        margin-bottom: 25px;
        width: 100% !important;
    }

    .blog-h22 {
        font-size: 16px;
        line-height: 23px;
    }

    .read-more{
        font-size: 10px !important;
    }


}