.outing-div-1{
    margin-top: 3.5rem;
    background-color:  #BFE5EF;
}
.outing-title{
    color:  #005082;

    font-weight: 600;
}
.arrow{
    background-color: transparent;
}
.pr-nt{
    width: 30px;
}
.arrow-img{
    background-color: transparent;
}
.pr-nt-2{
    z-index:22;
}
.custom-arrow{
    background-color: transparent;
    width: 40px;
}
.latest-text{
    font-size: 20px!important;
}
.arrow-wrapper{
    text-align: center;
}
.img{
    border-radius: 10px;
}
.carousel-wrapper{
    margin: auto;
    /* width: 50%; */
}
.slick-next:before {
    display: none;
}
.pagination-btn{
    border-radius: 20px;
    background-color: #03A6CA;
}
.outingImg{
    border-radius: 10px;
}
.carousel .control-dots {
    display: none!important;
}