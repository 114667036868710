.BoxSection-container1-wrapper {
  background-color: #0c6ca8;
  border-radius: 40px;
  /* z-index: 111; */
  padding: 15px 20px;
  margin-top: 20px;
  color: white;
  width: 60%;
  margin: auto;
  /* margin: 150px 0px; */
  /* margin-bottom: 80px; */
  /* margin-bottom: 80px; */
}

.BoxSection-Btn button a {
  text-decoration: none;
  color: black;
}
.BoxSection-Btn button a:hover {
  color: #005082;
}

.BoxSection-Btn {
  max-width: 200px;
  margin: auto;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 25px 5px 25px 5px;
  border: 2px solid #095687;
  color: #095687!important;
  background: white!important;
  padding: 10px 20px;
  transition: 0.3s ease-in-out;
}

.BoxSection-Btn:hover {
  /* background: rgb(191, 235, 250, 0.3); */
  color: white;
  border: 2px solid #0c6ca8;
  /* outline: 1px solid white; */
  background-color: white !important;
  box-shadow: 5px 5px 5px 0px white;
  transition: 0.3s ease-in-out;
}
.BoxSection-container1-wrapper h3 {
  font-size: 20px;
}
@media only screen and (max-width: 992px) {
  .BoxSection-container1-wrapper {
    width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  .BoxSection-container1-wrapper h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 375px) {
  .BoxSection-container1-wrapper {
    margin-top: 80px;
    width: 90%;
  }
  .BoxSection-container1-wrapper h3 {
    font-size: 18px;
  }
}
/* @media only screen and (max-width: 992px) {
  .BoxSection-container1-wrapper {
    width: 80%;
  }
} */
