.video-container {
  /* height: 300px;
    width: 300px; */
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}

.home-top-banner {
  text-align: center;
  background: #bfebfa;
}

.home-top-banner .presentation-mode {
  margin: auto;
  overflow-x: hidden;
}

.banner-content-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_content {
  z-index: 99;
  padding: 40px;
  text-align: left;
  color: #000;
  max-width: 55%;
  background: #ffffff99;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 25px;
  box-shadow: 2px 2px 10px 2px #00000020;
}

.banner_content h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.banner_content .underline::after {
  left: 52%;
}

.banner_content h1 {
  font-weight: 600;
  color: #005082;
  margin-bottom: 8px !important;
  line-height: 1.3;
}

.banner_content p {
  font-size: 18px !important;
  margin-bottom: 24px !important;
}

.underline {
  position: relative;
  max-width: max-content;
}

.underline::after {
  content: "";
  height: 2px;
  border-radius: 50px;
  left: 60%;
  transform: translate(-50%, 0);
  background: #005082;
  width: 100%;
  position: absolute;
  bottom: -24%;
}

.HomeHeaderBtn {
  /* background: #00508250; */
  border: 2px solid #005082;
  /* color: #fff; */
  /* border-radius: 50px; */
  margin: 5px 5px;
  text-decoration: none;
  /* padding: 2px 20px !important; */
  font-size: 16px;
  transition: 0.3s all;
  max-width: max-content;
}

.HomeHeaderBtn:hover {
  color: #005082 !important;
  background: white !important;
  border: 2px solid #005082;
  box-shadow: 5px 5px 5px 0px #005082;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .banner_content h1 {
    font-size: 30px;
  }

  .tabsForServices .react-tabs__tab {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .banner_content {
    max-width: 60%;
  }

  .tabsForServices .tab-cntnt .technologies img {
    width: 50px;
  }

  .tabsForServices .tab-cntnt .technologies p {
    font-size: 14px;
  }

  .casestudy-slider .slideWrap img {
    margin-bottom: 20px;
  }

  .tabsForServices .sideImageWrap {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_content {
    z-index: 99;
    padding: 40px;
    text-align: left;
    max-width: 100%;
  }

  .tabsForServices .tab-cntnt .technologies .singleTechWrap .singleTech {
    margin: 24px auto 0;
    max-width: max-content;
    padding: 16px;
  }

  .presentation-mode .my-slide {
    padding: 100px 20px 120px;
  }

  .banner_content h1 {
    line-height: 1.5;
    font-size: 28px;
  }
}

.banner-slider {
  background: url("https://53.fs1.hubspotusercontent-na1.net/hub/53/hubfs/customer-iinteraction.webp?width=893&height=600&name=customer-iinteraction.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  /* color: #fff; */
  display: flex;
  align-items: center;
  z-index: -1;
  /* background-color: #000; */
}

.bg-img {
  background-color: #00000090;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.banner-slider h1 {
  /* color: #fff; */
  z-index: 1;
}

.LakeHome-Whyus-background2 {
  display: none;
}