.tech-main {
    margin-top: 140px;
    margin-bottom: 60px;
position: relative;
    /* margin: 0 60px; */
    display: flex;
    flex-direction: column;
}

.tech-main2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 80px;
    /* margin-left: 60px; */
}

.tech-1 {
    width: 100%;
}

.backg {
    width: 50%;
}

.tech-2 {
    display: flex;
    max-width: 1000px;
    height: 420px;
    margin: auto;
    flex-direction: column-reverse;
    background-color: rgba(226, 249, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tech-texts{
    width: 80%;
}

.back_in {
    padding: 0;
    margin-left: 30px;

}

.tech-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
}

.tech-4 {
    /* width: 60%; */
    width: 50%;
    margin-right: -15px;
    /* margin-right: 120px ; */
}

.tech-4-h2 {
    font-size: 42px;
}

.t-div {
    /* width: 90%; */
    padding: 20px 10px 20px 20px;
}

.t2-h {
    font-size: 20px;
    padding:  0 0 10px 0;
    line-height: 30px;
    color: rgba(0, 0, 0, 1);
}



.btn-3 {
    width: 198px;
    /* height: 31px; */
    top: 231px;
    left: 36px;
    padding: 5px;
    margin-top: 10px;
    border-radius: 30px 10px 30px 10px;
    gap: 10px;
    border: 2px solid rgba(9, 81, 130, 1);
    color: rgba(217, 217, 217, 1);
    background-color: rgba(9, 81, 130, 1);
}

.btn-3:hover {
    background-color: white;
    color: rgba(9, 81, 130, 1);
}

.btn-4 {
    width: 115px;
    /* height: 31px; */
    padding: 5px;
    border-radius: 30px 10px 30px 10px;
    border: 1px;
    margin-left: 10px;
    gap: 10px;
    color: rgba(9, 81, 130, 1);
    background-color: white;
    border: solid 2px rgba(9, 81, 130, 1);
}

.btn-4:hover {
    background-color: rgba(9, 81, 130, 1);
    color: white;
}

.btns {
    gap: 10px;
}

.c-btns {
    margin: 20px 20px 20px 0px;
}

.imagebox {
    background-color: white;
    display: flex;
    justify-content: center;
}

.slick-prev {
    left: 69px !important;
}

.slick-next {
    left: 136px !important;
}

.slick-next:before,
.slick-prev:before {
    content: "" !important;
}

.next-slick-arrow,
.prev-slick-arrow {
    color: #000000;
    font-size: 45px;
}

.tech-h1 {
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.bbh {
    padding: 20px;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    padding: 30px 30px 10px 30px;
    text-align: left;
}

.t2-p {
    color: rgba(111, 108, 108, 1);
    font-size: 16px;
}

/* .slider{
    position: relative;
} */

.slid-btns{
    /* position: absolute;
    left: 0;
    top: 360px; */
    margin-top: 30px;
}
.tech-img{
    width: 50%;
}

.img-div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.left{
    height: 50px;
    color: rgba(9, 81, 130, 1);
    margin-right: 30px;
}
.right{
   height :50px;
   color: rgba(9, 81, 130, 1);
   
}

.tech-h2 {
    font-family: 'Lato', sans-serif;
    color: rgba(9, 81, 130, 1);
    font-size: 42px;
    font-weight: 600;
    line-height: 55px;
    width: 90%;
    margin: 20px 0;
    text-align: left;
}

.tech4-p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    width: 90%;
    font-weight: 400;
    line-height: 22px;
    color: rgba(51, 51, 51, 1);
}

.tech-h22 {
    font-family: 'Lato', sans-serif;
    color: rgba(9, 81, 130, 1);
    font-size: 42px;
    margin: 20px 0;
    /* padding: 0 185px 0 0; */
    font-weight: 600;
    line-height: 55px;
    text-align: left;
}

.tech-p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    width: 90%;
    color: rgba(51, 51, 51, 1);
}

.c-btn {
    border: none;
    background-color: white;
    margin-left: 10px;
}

.tech-divs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.react-multiple-carousel__arrow--right {
    background: rgba(187, 235, 250, 0.5);
}
.react-multiple-carousel__arrow--left {
    background: rgba(187, 235, 250, 0.5);

}

.icons{
    margin-left: 50px;
}

@media screen and (max-width: 1200px) {
    .btn-4 {
        width: 198px;
    }
}

@media screen and (max-width:992px) {
    .tech-h1 {
        font-size: 18px;
        line-height: 23px;
    }

    .tech-divs{
        align-items: baseline;
    }

    .tech-h1{
        text-align: center;
    }    

    .tech-h22 {
        font-size: 30px;
        text-align: center;
        line-height: 30px;
    }

    .tech-4{
        margin: 0;
        width: 100%;
    }

    .tech-h2 {
        font-size: 26px;
        line-height: 32px;
        width: 100%;
        text-align: center;
    }

    .btn-3{
        width: 195px;
        margin: 10px 0;
    }
     .btn-4{
        width: 150px;
     }
    .tech-divs {
        flex-direction: column;
        align-items: center;
    }

    .tech-1 {
        width: 100%;
    }

    .tech-2 {
        /* width: 100%; */
        width: 55vw;
        margin: 0;
        margin-top: 50px;
        height: auto;
        
    }

    .icon {
        width: 100% !important;
    }
    .icons{
        margin: 50px  0 0 0;
    }
    .tech-main {
        margin-top: 80px;
    }
    .backg{
        width: 100% !important;
    }

    .slid-btns{
        display: flex;
    justify-content: center;
    }

    .tech-p{
        width: 100%;
        text-align: center;
    }

    .tech4-p{
        width: 100%;
        text-align: center;
    }
     

    .tech-texts{
        width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    }

    .tech-main2{
        width: 100%;
        margin-top: 20px;
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:600px) {

    .tech-main {
        margin-top: 30px;
        margin-bottom: 0px;
        /* margin: 0px; */
    }

    .tech-4 {
        width: 100%;
        margin: 10px 0 30px 0px;
        margin-bottom: 30px;
    }

    .tech-divs {
        flex-direction: column;
    }

    .imagebox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .imageboxImg{
        width: 40%;
    }
    .tech-1 {
        width: 100%;
    }

    .tech-2 {
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 15px !important;
        margin: 0;
        flex-direction: column-reverse;
    }
    .slid-btns{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .left {
        height: 35px;
        margin-right: 20px;
    }

    .right{
        height: 35px;
    }

    .btn-3 {
        padding: 5px;
        font-size: 10px;
        width: 50%;
    }

    .btn-4 {
        padding: 5px;
        font-size: 10px;
        width: 45%;
    }

    .tech-h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        margin: 15px 0 0 0;
    }

    .tech-h2 {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 15px;
    }

    .tech-p {
        margin-bottom: 15px;
        font-size: 12px !important;
        line-height: 18px;
    }

    .tech-h22 {
        font-size: 16px;
        line-height: 21px;
        padding: 0;
    }

    .t-div {
        width: 100%;
    }

    .t2-p {
        font-size: 12px !important;
    }

    .t2-h {
        font-size: 13px;
    }

    .c-btn {
        margin-top: 10px;
    }

    .tech-main2 {
        flex-direction: column-reverse;
        margin: 0;
    }

    .tecg4-p {
        font-size: 12px;
    }

    .icons {
        width: 100%;
        display: flex;
        margin: 0;
    }

    .icon {
        width: 50% !important;
        margin: auto;
    }
    .tech-texts{
        width: 100%;
    }
.tech4-p{
    font-size: 12px !important;
    line-height: 18px;
}
}