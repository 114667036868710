* {
    margin: 0;
    padding: 0;
}

.transcript-footer-container{
    margin-top: 144px;
    padding-bottom: 71px;
}
.live-footer-container {
    max-width: 1261px;
    height: 204px;
    flex-shrink: 0;

    border-radius: 24px;
    background: #00A8CD;
}

.live-footer-text {
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.live-footer-img {
    width: 232px;
    height: 55px;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid #00A8CD;
    background: #FFF;
    box-shadow: 0px 3px 24px 4px rgba(0, 0, 0, 0.06);
}

.live-footer-mail {
    color: #00A8CD;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}
.footer-img1{
    width: 20px;
}

@media screen and (max-width: 1200px){
    .transcript-footer-container{
        margin-top: 12vw;
    padding-bottom: 7vw;
    }
    .live-footer-container {
        max-width: 1261px;
        height: 204px;
    }
    
    .live-footer-text {
        font-size: 3.4vw;
    }
    
    .live-footer-img {
        width: 20vw;
        height: 55px;
    }
    
    .live-footer-mail {
        font-size: 1.9vw;
    }
}
@media screen and (max-width: 992px){
    .live-footer-container {
        max-width: 1261px;
        height: 25vw;
    }
    .live-footer-img {
        width: 20vw;
        height: 6vw;
    }
    .footer-img1{
        width: 2vw;
    }
}