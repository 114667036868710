
.LakeHome-CaseS-Heading {
  text-align: center;
  /* width: fit-content; */
}

.LakeHome-case-logo {
  max-width: 150px;
}

.Lake-Case-Carousel-Container {
  min-height: 50vh;
}

.row {
  border-radius: 15px;
}

.Product-Carousel-Container {
  min-height: 80vh;
  display: flex !important;
  justify-content: space-between;
}

.LakeHome-CaseS-Heading h2 {
  color: #005082;
}

.Lake-Case-Carousel-Wrapper {
  text-align: left;
}

.LaKeHome-Case-Carousel-Heading h3 {
  font-size: 30px;
  font-weight: 800;
  color: #005082;
}

.LaKeHome-Case-Carousel-Para h6 {
  font-size: 20px;
}

.LaKeHome-Case-Carousel-Para p {
  font-size: 16px;
}

.LakeHome-CaseS-Heading p {
  text-transform: capitalize;
}

.Lake-Case-Carousel-Btn button a {
  text-decoration: none;
  color: black;
}

.Lake-Case-Carousel-Btn button a:hover {
  color: #005082;
}

.Lake-Case-Carousel-Btn button {
  font-family: lato;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 25px 5px 25px 5px;
  border: 2px solid #005082;
  background: transparent;
  padding: 10px 30px;
  transition: 0.3s ease-in-out;
}

.Lake-Case-Carousel-Btn button:hover {
  /* background: rgb(191, 235, 250, 0.3); */
  color: #005082;
  box-shadow: 5px 5px 5px 0px #005082;
  transition: 0.3s ease-in-out;
}

.category h5 {
  font-size: 16px;
  color: #005082;
}

.pagination {
  font-size: 16px;
  justify-content: center;
  color: black;
  flex-direction: row;
  gap: 25px;
  list-style-type: unset;
  padding: 8px 10px;
  border-radius: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pagination-item {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: aliceblue;
  border-radius: 5px;
}

.pagination-item:hover {
  cursor: pointer;
  background-color: #e1e4e7;
  -webkit-transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  -o-transition: background-color .1s linear;
  transition: background-color .1s linear;
}

.pagination-item-active {
  /* color: white; */
  /* background-color: #0a7ea3; */
  border-bottom: 2px solid #0a7ea3;
  border-radius: 0;
  pointer-events: none;
  -webkit-transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  -o-transition: background-color .1s linear;
  transition: background-color .1s linear;
}

.pagination-item-active:hover {
  background-color: #0a7ea3;
}

.hide-imgurl{
  display: none;
}

.Lake-Case-Carousel-image img{
  height: 35vh !important;
}

.elementor-widget-container button{
  background: linear-gradient( 90deg, rgba(191, 235, 250, 1) 0%, rgba(159, 209, 230, 1) 0%, rgba(107, 166, 197, 1) 0%, rgba(77, 142, 178, 1) 0%, rgba(0, 80, 130, 1) 100%, rgba(0, 212, 255, 1) 100% );
  color: #fff;
  outline: none;
  transition:all 0.5s ease!important;
}
.elementor-widget-container button:hover{
  background: transparent!important;
  color: #005082!important;
  border: 1px solid #005082;
  transition:all 0.5s ease!important;
}
.cat-item.active {
  box-sizing: border-box;
  color: #005082!important;
  border: 1px solid #005082 !important;
  background: transparent!important;
  transition:all 0.5s ease!important;
}


.btnbtn{
  position: relative;
  right: 250px;
}
.btnbtn button{
  color: black !important;
}

@media (max-width: 992px) {

  .Lake-Case-Carousel-Wrapper {
    margin-right: 100px;
  }

  .pagination {
    font-size: 16px;
    gap: 25px;
  }

  .case-border{
    padding: 15px 0 !important;
  }
  .LaKeHome-Case-Carousel-Para {
    padding-right: 130px;
  }
  .hide{
    display: none;
  }
  .hide-imgurl{
    display: block;
  }
  .Lake-Case-Carousel-image img{
    height: 100% !important;
  }
}

@media (max-width: 768px) {

  .Lake-Case-Carousel-Wrapper {
    margin-right: 0px;
  }

  .pagination {
    font-size: 16px;
    gap: 25px;
  }

  .LaKeHome-Case-Carousel-Para {
    padding-right: 0px;
  }
  .Case-Container{
    width: fit-content;
    height: 380px;
    display: block;
    margin-left: 0 !important;
    margin-right: 30px !important;
  }


}

@media (max-width: 576px) {

  .img-container {
    padding: 0;
  }

  .Lake-Case-Carousel-ImageWrapper {
    width: 260px;
  }

  .Lake-Case-Carousel-Wrapper {
    margin-right: 0px;
  }

  .pagination {
    font-size: 16px;
    gap: 10px;
  }
  .elementor-widget-container {
    text-align: center;
  }

}