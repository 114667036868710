* {
    margin: 0;
    padding: 0;
}

.OurCommitment-main {
    gap: 12px;
    margin-top: 100px;
    background: #E1F1FA;
    padding: 88px 180px;
}

.OurCommitment-main-container {
    width: 1512px;
    /* width: 50vw; */
    height: 787px;
    flex-shrink: 0;
}

.OurCommitment-text {
    max-width: 650px;
    /* max-width: 50%; */
    margin: auto;
    /* padding-bottom: 40px; */
}

.OurCommitment-text h4 {
    /* color: #333; */
    /* text-align: center; */
    /* font-size: 16px; */
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    /* font-style: normal; */
    /* font-weight: 400; */
    /* line-height: normal; */
    /* letter-spacing: 2.48px; */

    text-transform: uppercase;
}

.OurCommitment-text h2 {
    color: var(--high, #095182);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.OurCommitment-text p {
    width: 90% !important;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 12px;
    /* padding: 0 20%; */
}

/* .OurCommitment-img img {
    width: 100%;
    height: 100%;
} */

.OurCommitment-img {
    width: 90px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .OurCommitment-compactness{
    width: 70vw;
} */
.OurCommitment-heading h6 {
    color: #333;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    /* line-height: normal; */
    line-height: 24px;

    /* margin-top: 23px; */
    margin-bottom: 10px;
}

.OurCommitment-container {
    display: flex;
    flex-direction: column;
    gap: 88px;
}

.OurCommitment-para p {
    color: #5C5C5C;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 0px;
    /* padding: 0px 20%; */

}



.OurCommitment-Nthchild {
    display: flex;
    height: 95%;
    padding: 70px 0 50px 0;
    border-radius: 14px;
    /* flex-direction: column;
    align-items: center;
    gap: 25px;
    flex-shrink: 0; */
    position: relative;
    /* border-radius: 14px; */

    border-radius: 30px 10px;
    border: 1px solid var(--high, #095182);
    background: #FFF;
    box-shadow: 0px 4px 0px 0px #095182;
    /* background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05); */

}

@media screen and (max-width: 1400px) {

    /* .OurCommitment-text p {
        letter-spacing: 0.8px;
    } */

    .OurCommitment-container {
        /* gap: 3vw; */
    }

    .OurCommitment-img {
        /* width: 6vw; */
    }

    .OurCommitment-main {
        padding: 88px 0;
    }

    /* .OurCommitment-container {
        gap: 3vw;
    } */


}

@media screen and (max-width: 768px) {
    .OurCommitment-parent {
        justify-content: center !important;
        row-gap: 65px;
    }

    .OurCommitment-text {
        /* text-align: center; */
        max-width: 100%;
    }

    /* .OurCommitment-text p{
        padding: 0 5%;
    } */
}

@media screen and (max-width: 576px) {

    .OurCommitment-img {
        width: 17vw !important;
        /* margin-top: 20px; */
    }

    .OurCommitment-text {
        padding: 10px;
    }

    .OurCommitment-main {
        padding: 70px 0;
    }

    /* .OurCommitment-text p {
        margin-top: 3vw;
        padding: 0 25px;
    } */

    .OurCommitment-container {
        /* gap: 3vw; */
        /* gap: 8vw; */
    }

    .OurCommitment-img {
        width: 5vw;
        /* width: 9vw; */
    }



    /* .OurCommitment-container {
        gap: 8vw;
    } */

    .OurCommitment-para p {
        /* font-size: 1.2vw; */
        padding: 0 10px;
    }

    .OurCommitment-Nthchild {
        display: flex;
        width: 100%;
        padding: 45px 16px;
        flex-direction: column;
        align-items: center;
        gap: 23px;
        flex-shrink: 0;
        background: #FFF;
    }
}