/* product section 3*/
.stepbystep {
  margin-top: 40px;
}

.subheading-stepbystep {
  font-size: 16px;
  font-weight: 600;
}

.containt-stepbystep {
  text-align: right;
  padding-left: 30px;
  justify-content: center !important;
  display: flex;
  /* align-items: flex-end;
  justify-content: left; */
  flex-direction: column;
}

.row-reverse-stepbystep {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.row-reverse-stepbystep .containt-stepbystep {
  text-align: left;
  /* padding-left: 30px; */
  /* padding-top: 50px; */
  /* display: flex;
  align-items: flex-start;
  justify-content: left; */
  flex-direction: column;
}

.icon-stepbystep {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.icon-stepbystep img {
  width: 100px;

}

.numbring-stepbystep {
  /* position: absolute; */
  border-radius: 500px;
  background: linear-gradient(90deg, rgba(191, 235, 250, 1) 0%, rgba(159, 209, 230, 1) 0%, rgba(107, 166, 197, 1) 0%, rgba(77, 142, 178, 1) 0%, rgba(0, 80, 130, 1) 100%, rgba(0, 212, 255, 1) 100%) !important;
  text-align: center;
  width: 95px;
  height: 93px;
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-weight: 800;
  font-size: 50px;
  margin-bottom: 10px;
}

.numbring-stepbystep-reverse {
  position: absolute;
  border-radius: 500px;
  background: linear-gradient(270deg, rgba(191, 235, 250, 1) 0%, rgba(159, 209, 230, 1) 0%, rgba(107, 166, 197, 1) 0%, rgba(77, 142, 178, 1) 0%, rgba(0, 80, 130, 1) 100%, rgba(0, 212, 255, 1) 100%);
  text-align: center;
  width: 95px;
  height: 93px;
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-weight: 800;
  font-size: 50px;
  margin-bottom: 10px;
}

.video-stepbystep {
  width: 100px;
  height: 50px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.row-stepbystep {
  margin-top: 20px;
  margin-bottom: 20px;
}

.video-stepbystep {
  width: 100px;
  height: 50px;
}

.carousel-section5{
  z-index: 22;
}

@media only screen and (min-width: 767px) {
.stepbystep-small0{
  padding: 5px 5px 5px 20px;
}

.stepbystep-small{
  padding: 5px 5px 5px 20px;
  display: flex;
  flex-direction: row-reverse;
}

}

@media only screen and (max-width: 575px) {
  /* .stepbystep-small0{
    padding: 5px 5px 5px 100px;
  }
  
  .stepbystep-small{
    padding: 5px 5px 5px 100px;
    
  }  */
  .icons-stepbystep{
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

/* product section 4*/



.row-reverse-benifits {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-benifit {
  margin-bottom: 25%;
  margin-top: 25%;
}

.colm-2 {
  display: none !important;
}

.para-benifits0{
  display: none;
}



.para-benifits {
  margin: 10px;
  width: 75%;
}

@media only screen and (max-width: 1200px) {
  .content-benifit {
    margin-bottom: 5%;
    margin-top: 17%;
  }
}


@media only screen and (max-width: 992px) {
  .content-benifit {
    margin-bottom: 30%;
    margin-top: 0%;
  }

  .para-benifits {
    margin: 0px;
    width: 65%;
  }

  .img-content-benifit {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .content-benifit {
    margin-bottom: 5%;
  }

  .para-benifits {
    margin: 10px;
    width: 100%;
    text-align: left;
  }

  .img-benifits {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px;
  }

  .img-content-benifit {
    margin-bottom: 50px;
    width: 100%;
    margin-top: 40px;
  }

  /* stepbystep */

  .icon-stepbystep {
    /* margin-top: 70px; */
    /* margin-bottom: 90px; */
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
    
  }


  .containt-stepbystep {
    text-align: left;
    display: flex;
    justify-content: l;
    flex-direction: column;
  }

  .numbring-stepbystep-reverse {
    text-align: center;
    width: 75px;
    height: 73px;
    /* margin-top: 11px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
/* 
  .numbring-stepbystep {
    text-align: center;
    width: 75px;
    height: 73px;
    margin-top: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  } */
  .icon-stepbystep1{
    display: none !important;
  }
  .icon-stepbystep0{
    display: block !important;
  }
  .hide-benifits{
    display: none !important;
  }

  .para-benifits {
    margin: 0px;
    width: 0%;
  }
  
  .img-content-benifit{
    display: none;
  }
  
  .img-benifits {
    width: 0px;
  }

  .content-benifit0{
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  .colm-2{
    display: block !important;
  }

  .colm{
    display: none !important;
  }

  .para-benifits0{
    display: flex;
    align-items: center;
  }
  .content-benifits{
    justify-content: center;
    align-items: center;
    padding-left: 15%;
  }
  .numbring-stepbystep {
    width: 60px;
    height: 60px;
    margin-left: -12px;
    justify-content: center;
    padding: 20px;
    margin-left: 10px;
    border-radius: 500px;
  }
}

/* product 5 */

.img-product-page-5 {
  padding: 30px;
  width: 350px;
  height: 250px;
}

.button-product-page-5 {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-product-page-5:hover, .button-product-page-5:active, .button-product-page-5:visited{
  text-decoration: none;
}


@media only screen and (max-width: 992px) {
  .img-product-page-5{
    padding: 5%;
    margin: -20px;
  }
  .button-product-page-5 {
    padding: 5%;
    padding-top: 10%;
  }
}

@media only screen and (max-width: 425px) {
  .img-product-page-5{
    padding: 5%;
    margin: -50px;
  }
  .numbring-stepbystep-reverse {
    width: 60px;
    height: 60px;
  }
  .numbring-stepbystep {
    width: 60px;
    height: 60px;
    margin-left: -12px;
    justify-content: center;
    padding: 20px;
    margin-left: 10px;
    border-radius: 500px;
  }
  .img-content-benifit{
    width:100% !important;
  }
  
}

@media only screen and (max-width: 320px) {
  .img-product-page-5{
    padding: 5%;
    margin: -70px;
  }
  .button-product-page-5 {
    margin-left: 10%;
    margin-right: 10%;
  }
  .button-product-page-5 .btn{
    min-width: 103px;
    min-height: 36px;
  }
  .card-product-page-5 h4 {
    font-size: 15px;
  }
  .numbring-stepbystep {
    height: 60px;
    padding: 20px;
    margin-left: 10px;
    justify-content: center;
    border-radius: 500px;
  }
}

.our-test-heading-t-0 .btn {
  min-width: 139px;
}

.popup2-form input{
  width: 100%;
}

.popup2-form{
  width: 100%;
}

.popup2-form button{
  border: none;
  background-color: transparent;
  margin-top: 20px;
  
}

.subscription-close-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  
}
.banner_content-subscription input,textarea{
border-radius: 12px;
border: 1px solid grey;
padding: 5px 10px;
}

.banner_content-subscription {
  z-index: 99;
  padding: 40px;
  text-align: left;
  color: #000;
  max-width: 85%;
  background: #ffffff99;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 30px; 
  box-shadow: 2px 2px 10px 2px #00000020;
}

.banner_content-subscription h6{
  font-weight: 700;
}

.banner-content-product{
  padding: 40px;
  text-align: left;
  color: #000;
  background: #ffffff99;
  backdrop-filter: blur(5px);
  border-radius: 25px;
  box-shadow: 2px 2px 10px 2px #00000020;
}



@media only screen and (min-width: 768px) {
  .img-benifits {
    width: 60px;
    height: 60px;
  }
  .img-benifits0{
    display: none !important;
  }
}