* {
    margin: 0;
    padding: 0;
}


.ProblemSolving-Person-img-div{
    /* object-fit: contain; */
    background-color: white;
}

.ProblemSolving-Person-text {
    text-align: left;
    padding: 20px 20px 20px 30px;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    /* transition: box-shadow 0.5s ease !important; */
}

.ProblemSolving-Person-text:hover {
    /* border: 0px 0 2px  2px solid rgba(3, 166, 202, 1); */
    /* border:0px 2px solid rgba(3, 166, 202, 1); */
    padding: 20px 20px 10px 28px;
    border-color: transparent rgba(3, 166, 202, 1) rgba(3, 166, 202, 1) rgba(3, 166, 202, 1);
    border-width: 0px 2px 2px 2px;
    border-style: none solid solid solid;
    box-shadow: 0px 8px 0px 0px rgba(3, 166, 202, 1);
}


.ProblemSolving-Person-text h4 {
    color: #333;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.86px;
    text-transform: uppercase;
}

.ProblemSolving-Person-text h2 {
    color: var(--high, #095182);
    font-size: 22px;
    margin-top: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}

.ProblemSolving-Person-text span {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ProblemSolving-Person-text button,
.ProblemSolving-button button {
    display: flex;
    width: 120px !important;
    height: 40px !important;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    transition: box-shadow 0.5s ease !important;

    border-radius: 25px 5px;
    background: #095182;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 18px;
}

.ProblemSolving-Person-text button:hover,
.ProblemSolving-button button:hover {
    color: #005082 !important;
    background: white !important;
    border: 2px solid #005082;
    box-shadow: 5px 5px 5px 0px #005082;
    font-weight: 600;
}


.ProblemSolving-button button {
    margin: 0;
}

.ProblemSolving-button {
    margin-bottom: 30px !important;
    max-width: 1300px;
    margin: auto;
}

.ProblemSolving-Container {
    position: relative;
    /* background: #E1F1FA; */
    padding: 70px 0;
    margin-top: 128px;
}

.ProblemSolving-PreClick {
    position: absolute;
    top: 50%;
    color: #095182;
    border-radius: 50%;
    z-index: 99;
}

.ProblemSolving-NextClick {
    position: absolute;
    right: 0;
    top: 50%;
    color: #095182;
    border-radius: 50%;
}

.ProblemSolving-heading {
    margin-bottom: 88px;
}

.ProblemSolving-heading h4 {
    color: #333;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.79px;
    text-transform: uppercase;
}

.ProblemSolving-heading h2 {
    color: var(--high, #095182);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ProblemSolving-heading p {
    width: 60%;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 12px;
    padding: 0 12%;
}

.Carousel-mobile {
    display: none;
}
.ProblemSolving-Person-img-div{
    height: 210px;
}
.ProblemSolving-Person-img{
    width: 100% !important;
    height: 100% !important;
}

@media screen and (max-width: 576px) {
    .ProblemSolving-heading p {
        /* font-size: 4vw; */
        width: 100%;
        padding: 0 5%;
    }

    .Carousel-desktop {
        display: none;
    }

    .Carousel-mobile {
        display: block;
    }
  
}