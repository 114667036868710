.faqDivPart1{
    color: rgba(83, 25, 251, 1);
        /* font-family: "Poppins"; */
        margin-top: 106px;
}
.faqDivPart2 span {
    color: #4b4b4b;
    /* font-family: "Poppins"; */

}
.faqMainDiv {
    display: flex;
   flex-direction: column;
   margin-top: 50px;
   margin-bottom: 20px;
}
.faqDivPart1{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* margin-top: 80px; */
}
.faqDivPart1 h2{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: -7px; */
    margin-bottom: 20px;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
}



hr.p{
    border: block;
    height: 2px;
    width: 96%;
    color:#000000
}

.faqDivPart3{
    display: flex;
    flex-direction: column;
    width: 76%;
    justify-content: center;
    align-items: left;
    color: #000;
    margin-top: 0px;
    /* font-family: "Poppins"; */
    margin: auto;
    font-size: 11px;
}
.Faq{
margin: 20px 20px 20px 20px;
font-size: 15px;
width: 70%;
}
#FaqId{
    display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 600;
        
}

/* .f-head::after{
color: blue;
} */
#FaqId.f-head:hover{
    color: blue;
    /* border-bottom: 3px solid blue; */
}
.activeButton, .faqtitle:checked{
    color: blue;
    border-bottom: 3px solid blue
}

#faqpara{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
}

#FaqId button{
  /* margin-left: 90px; */
  justify-content: end;
  align-items: end;
  justify-content: flex-end;
background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font: icon;
}
.faqtitle{
    border-bottom: 1px solid black;
}
.activeButton {
    border: 2px solid blue;


}

.faq-text{
    /* font-family: 'Poppins'; */
font-size: 22px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0.02em;
text-align: center;
width: 70%;
margin-bottom: 40px;
color: rgba(51, 51, 51, 1);

}
  

@media screen and (max-width:500px) {
    .faqDivPart1 h2{
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-bottom: -7px; */
        margin-bottom: 20px;
        font-size: 20px;
        color: rgba(83, 25, 251, 1);
        font-weight: bold;
    }

    .faq-text{
        font-size: 14px;
        line-height: 18px;
    }

   #FaqId{
    font-size: 14px;
   }

   #faqpara{
    font-size: 14px;
    line-height: 16px;
   }

   .faqMainDiv{
    margin: auto;
   }

   .faqDivPart1{
    margin-top: 50px ;
   }

}

@media (min-width: 501px) and (max-width: 800px) {
.faqDivPart1 h2 {
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: -7px; */
    margin-bottom: 20px;
    /* font-size: 38px; */
    font-weight: bold;
  }
  .faq-text{
  font-size: 16px;
      font-weight: 400;
      /* line-height: 33px; */
      /* letter-spacing: 0.02em; */
      text-align: center;
      width: 70%;
      margin-bottom: 40px;
      color: rgba(51, 51, 51, 1);
  }
  #FaqId{
    font-size: 20px;
  }
}