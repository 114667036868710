.app-main{
  /* background-image: url(../../../images/chrome-extension-develpment/BG.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 100%; */
  /* max-width: 1600px; */
  margin: auto;
  overflow: hidden;

}


h1 , h2{
  text-transform: initial !important;
}
 

