.c-bg{
    background-color: rgba(226, 249, 255, 1);
    object-fit: cover;
}

.connect-main {
    background-color: rgba(226, 249, 255, 1);
    display: flex;
    object-fit: cover;
    flex-direction: column;
    margin-bottom: 60px;
    padding: 80px;
    margin-top: 120px;
}


.connect-2 {
    display: flex;
    margin: auto;
    /* margin-top: 20px; */
}

.k-div {
    display: flex;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 40px;
}

.k-divt {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 25px;
}


.connect-h1 {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;

}

.connect-h2 {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato', sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 58px;
    /* letter-spacing: 0.05em; */
    text-align: center;
    margin-bottom: 20px;
    /* padding: 40px; */
}

.connect-p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-align: center;
    /* padding: 40px; */
    width: 50%;
    margin: auto;
    color: rgba(51, 51, 51, 1);

}

.c-h {
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    margin-left: 20px !important;
    /* letter-spacing: 0.02em; */
    text-align: left;
    color: rgba(9, 81, 130, 1);
}


.c-p {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 400;
    /* width: 90%; */
    margin-left: 20px;
    line-height: 20px;
    /* letter-spacing: 0.01em; */
    text-align: left;
    color: rgba(111, 108, 108, 1);
}


.k-h {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato' sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.k-p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.con-btn {
    /* width: 180px;
    height: 33px;
    padding: 7px 20px 7px 20px;
    border-radius: 30px 10px 30px 10px;
    border: 1px solid rgba(9, 81, 130, 1);
    gap: 10px;
    color: rgba(9, 81, 130, 1);
    background-color: white;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left; */

    display: inline-flex;
    padding: 7px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px 10px;
    border: 1px solid var(--high, #095182);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--high, #095182);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
}

.con-btn:hover {
    background-color: rgba(9, 81, 130, 1);
    color: white;
}

.c-h {
    margin: 10px 0;
}

.c-1 {
    border-left: 3px solid rgba(9, 81, 130, 1);
    /* margin-left: 20px; */
    width: 33.33%;
}



@media screen and (max-width:992px) {}

@media screen and (max-width:600px) {

    .connect-main {
        padding: 10px;
        margin-top: 80px;
    }


    .connect-h1 {
        font-size: 15px;
        line-height: 12px;
        margin-bottom: 15px;
    }

    .connect-h2 {
        font-size: 18px;
        line-height: 15px;
    }

    .connect-p {
        font-size: 12px !important;
        line-height: 15px;
        width: 100%;
    }

    .k-div {
        flex-direction: column;
        margin-bottom: 0;
    }

    .k-imgD {
        display: flex;
        justify-content: center;
    }

    .k-divt {
        justify-content: center;
        gap: 0;
    }

    .k-h {
        font-size: 18px;
        text-align: center;
    }

    .k-p {
        font-size: 14px !important;
        margin: 10px 0;
    }


    .con-btn {
        margin: auto;
    }

    .c-h {
        font-size: 12px;
        line-height: 12px;
    }

    .c-p {
        font-size: 10px !important;
        line-height: 12px;
    }


    .connect-texts{
        margin-top: 20px;
    }
    .connect-2{
        width: 100%;
        flex-direction: column;
    }
    .c-1{
        /* margin: auto; */
        margin-top: 5vw;
        width: 100%;
    }
    .row{
        margin: 0;
    }

    .c-h{
        margin-left: 10px !important;
    }

    .c-p{
        margin-left: 10px !important;
    }
}





