.FooterCitiesLink-wrapper {
  background-color: #f1f4fb;
  padding: 28px 20px;
}

.FooterCitiesLink {
  float: left;
  font-size: 11px;
  line-height: 1.2;
  color: #4a4a4a7a;
  margin: 7px 5px;
  border-right: solid 3px hsl(0, 0%, 85%);
  padding-right: 5px;
}
.FooterCitiesLink a {
  color: #4a4a4a7a;
  cursor: pointer;
}
.FooterCitiesLink a:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .FooterCitiesLink-wrapper {
    display: none;
  }
}
