.error-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  /* width: 40%; */
}
.error-content-01 {
  text-align: center;
}
.error-content h1 {
  text-transform: uppercase;
  /* text-align: left;  */
  color: gray;
  font-weight: 900;
  font-size: 150px;
}
.error-content h6 {
  width: 50%;
  text-align: center;
  color: rgb(159, 159, 159);
}
.error-content .btn {
  margin-top: 30px;
  text-transform: uppercase;
  border: 2px gray solid;
  width: 80px;
  height: 40px;
  border-radius: 25px;
  vertical-align: middle;
  color: black;
}
@media screen and (max-width: 767px) {
  .error-content h3 {
    font-size: 20px;
  }
  .error-content h6 {
    font-size: 18px;
    font-weight: 400;
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .error-content h1 {
    font-size: 100px;
  }
  .error-content h3 {
    text-align: center;
  }
  .error-content h6 {
    text-align: center;
  }
}
.btn-Link-error{
  color: white;
}
.btn-Link-error:hover{
  font-weight: 600;
  color: white!important;
}
