.DriveMetrics-wrapper {
  margin-top: 260px;
}
.DriveMetrics-container2-wrapper {
  margin-top: 22px;
}
.DriveMetrics-wrapper-00 {
  background-color: #bfebfa;
}

.DriveMetrics-container1-wrapper {
  background-color: #0c6ca8;
  border-radius: 40px;
  margin-top: -150px;
  z-index: 111;
  padding: 12px 0px;
  color: white;
}

.Drive-heading h2 {
  font-weight: 600;
  color: #005082;
  margin-bottom: 24px !important;
}
.matrix-slider p {
  font-size: 26px;
  font-weight: 500;
}
.matrix-slider p span {
  font-weight: 800;
}


/*****ANIMATION******/
.change_content::after{
  content: '';
  animation: changetext 8s infinite linear;
  color:  #005082;
  font-size: 40px;
  text-shadow:  1px 1px 1px #000, 
  3px 3px 5px white;
} 
@keyframes changetext{
  100%{content:"Web Applications ";}
  0%{content:"Chrome Extension"}
}
