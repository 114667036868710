.SheduleCall-a-Photo {
  width: 150px;
  margin: auto;
  margin-top: 124px;
}
.SheduleCall-a-Photo img {
  width: 100%;
  margin-top: -84px;
  margin-bottom: 22px;
  box-shadow: -2px -1px 9px 1px #bfebfa;
  border-radius: 8px;
}
.SheduleCall-item-no {
  border: 1px solid grey !important;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: white;
}

.SheduleCall-Main-items-wrapper {
  box-shadow: 1px 2px 12px 2px #d7e1e4;
  background-color: #f0fbff;
}

.schedule-meeting-btn {
  background-color: #005082;
  color: white;
  font-weight: 500;
  padding: 10px 20px;
  border: 2px solid #005082;
  transition: all 0.3s ease;
}
.schedule-meeting-btn:hover {
  color: white !important;
  background-color: white !important;
  border: 2px solid #005082;
  font-weight: 500;
  padding: 10px 20px;
  box-shadow: 5px 5px 5px 0px #005082;
  transition: 0.3s all ease-in;
}

.SheduleCall-Wrapper .container h2 {
  color: #005082;
}
.SheduleCall-Wrapper .container h4 {
  margin-bottom: 0;
}
