* {
    margin: 0;
    padding: 0;
}

.OurApproach-text h4 {
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.48px;
    text-transform: uppercase;
}

.OurApproach-main-container {
    width: 100%;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 4rem;
}

.OurApproach-text h2 {
    color: var(--high, #095182);
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.OurApproach-text p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
    width: 55%;
}
.yourUser-text ul {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 2rem;
}


.OurApproach-main{
    background: #E1F1FA;
}
.OurApproach-main-container {
    width: 1512px;
    /* height: 1996px; */
    flex-shrink: 0;
    background: #E1F1FA;
    gap: 88px;
}

.yourUser {
    width: 1251px;
    height: 277px;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: #FFF;
    gap: 66px;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
}

.OurApproach-UserLeft {
    flex-direction: row-reverse;
    border-radius: 140px 140px 140px 140px;
    margin-bottom: 68px;
    /* gap: 50px; */
}

.OurApproach-UserRight {
    float: right;
    border-radius: 140px 140px 140px 140px;
    margin-bottom: 68px;
    /* gap: 50px; */
}

.yourUser-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: 690px;
}

.yourUser-text h4 {
    color: #2F2A2A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
}

.yourUser-text h2 {
    color: var(--high, #095182);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.yourUser-text p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
}

.OurApproach-image{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1500px) {

    .OurApproach-main-container{
        gap: 5vw;
    }
    .OurApproach-text h4 {
        font-size: 16px;
        letter-spacing: 2.48px;
    }
    
    .OurApproach-text h2 {
        font-size: 4vw;
    }
    
    .OurApproach-text p {
        font-size: 16px;
    }
    
    .OurApproach-main-container {
        width: 100%;
        height: auto;
        padding-top: 5vw;
    }
    
    .yourUser {
        width: 83%;
        /* height: 18.4vw; */
        gap: 4.4vw;
    }
    
    .OurApproach-UserLeft {
        border-radius: 0 140px 140px 0;
        margin-bottom: 4.5vw;
    }
    
    .OurApproach-UserRight {
        border-radius: 140px 0 0 140px;
        margin-bottom: 4.5vw;
    }
    
    .yourUser-text {
        gap: 0.7vw;
        width: 55%;
    }
    
    .yourUser-text h4 {
        /* font-size: 1.1vw; */
        letter-spacing: 0.96px;
    }
    
   
    .yourUser-text p {
        /* font-size: 1.1vw !important; */
        letter-spacing: 0.8px;
    }
    
    .OurApproach-imageContainer{
        width: 15%;
        height: auto;
    }
    .OurApproach-image{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 768px) {

    .OurApproach-text h4 {
        font-size: 16px;
        letter-spacing: 2.48px;
    }
    
    .OurApproach-text h2 {
        font-size: 36px;
        text-align: center;
    }
    
    .OurApproach-text p {
        font-size: 16px;
    }
    
    .OurApproach-main-container {
        width: 100%;
        height: auto;
        padding-top: 5vw;
    }
    
    .yourUser {
        width: 100%;
        /* height: 18.4vw; */
        gap: 4.4vw;
    }
    
    .OurApproach-UserLeft {
        border-radius: 0;
        margin-bottom: 4.5vw;
    }
    
    .OurApproach-UserRight {
        border-radius: 0;
        margin-bottom: 4.5vw;
    }
    
    .yourUser-text {
        gap: 0.7vw;
        width: 55%;
    }
    
    .yourUser-text h4 {
        /* font-size: 1.1vw; */
        letter-spacing: 0.96px;
    }
    
  
    .yourUser-text p {
        /* font-size: 1.1vw !important; */
        letter-spacing: 0.8px;
    }
    
    .OurApproach-imageContainer{
        width: 15%;
        height: auto;
    }
    .OurApproach-image{
        width: 100%;
        height: 100%;
    }
}



@media screen and (max-width: 576px) {

    .OurApproach-text h4 {
        font-size: 16px;
        letter-spacing: 2.48px;
    }
    
    .OurApproach-text h2 {
        font-size: 36px;
    }
    
    .OurApproach-text p {
        font-size: 16px !important;
        width: 90%;
    }
    
    
    .OurApproach-main-container {
        width: 100%;
        height: auto;
        padding-top: 5vw;
    }
    
    .yourUser {
        width: 100%;
        height: 30vw;
        gap: 4.4vw;
        padding: 35px 0 !important;
    }
    
    .OurApproach-UserLeft {
        border-radius: 0;
        margin-bottom: 4.5vw;
    }
    
    .OurApproach-UserRight {
        border-radius: 0;
        margin-bottom: 4.5vw;
    }
    
    .yourUser-text {
        gap: 0.7vw;
        width: 55%;
    }
    
    .yourUser-text h4 {
        font-size: 12px;
        letter-spacing: 0.96px;
    }
    
    .yourUser-text h2 {
        font-size: 20px;
    }
    
    .yourUser-text p {
        font-size: 12px !important;
        letter-spacing: 0.8px;
    }
    .yourUser-text ul {
        font-size: 12px;
        margin-left: 1.5rem;
    }

    .OurApproach-imageContainer{
        width: 15%;
        height: auto;
    }
    .OurApproach-image{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 576px) {
    .OurApproach-imageContainer{
        display: none;
    }
    .yourUser-text{
        width: 90%;
    }
    .yourUser {
        height: auto;
    }
    .OurApproach-main-container {
        gap: 10vw;
    }
    .OurApproach-UserRight {
        border-radius: 0;
        margin-bottom: 10vw;
    }
    .OurApproach-UserLeft {
        border-radius: 0;
        margin-bottom: 10vw;
    }
}