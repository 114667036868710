* {
    margin: 0;
    padding: 0;
}

.live-transcript-video {
    width: 85%;
    flex-direction: column;
}

.live-transcript-heading {
    color: #00A8CD;
    /* font-family: Montserrat; */
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.live-transcript-text {
    margin: 15px 0;
    color: #000;
    /* font-family: Montserrat; */
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    width: 85%;
}

.live-transcript-btn {
    display: inline-flex;
    height: 56px;
    padding: 2px 56px 4px 56px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 14px;
    background: #00A8CD;
    box-shadow: 0px 6px 20px 0px rgba(4, 100, 129, 0.30);

    color: #FFF;
    /* font-family: Poppins; */
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 95.238% */
    transition: all 0.3s;
}

.live-transcript-btn:hover {
    background: #00687E;
}

.live-transcript-100 {
    font-size: 16px;
    /* margin-top: 15px; */
}

.live-transcript-inBtn {
    gap: 15px;
}

.socialIcon {
    display: flex;
    gap: 20px;
    height: 30px;
    margin-top: 20px;
}

.youtubeLogo {
    width: 45px;
}

.spotifyLogo {
    width: 95px;
}

.zoomLogo {
    width: 110px;
}

.meetLogo {
    width: 40px;
}

.live-transcript-1002 {
    display: none;
}

@media screen and (max-width: 1200px) {
    .live-transcript-video {
        width: 85%;
    }

    .live-transcript-heading {
        font-size: 5vw;
    }

    .live-transcript-text {
        margin: 2vw 0;
        font-size: 3vw;
        line-height: 4vw;
        width: 90%;
    }

    .live-transcript-btn {
        height: 56px;
        padding: 2px 7vw 4px 7vw;
        font-size: 2.2vw;
    }

    .live-transcript-100 {
        font-size: 2vw;
        /* margin-top: 2vw; */
    }
}

@media screen and (max-width: 992px) {
    .socialIcon {
        display: flex;
        gap: 2vw;
        height: 4vw;
        margin-top: 2vw;
    }

    .youtubeLogo {
        width: 4.5vw;
    }

    .spotifyLogo {
        width: 10vw;
    }

    .zoomLogo {
        width: 11vw;
    }

    .meetLogo {
        width: 4vw;
    }

    .live-transcript-1002 {
        display: block;
    }

    .live-transcript-1001 {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .live-transcript-btn {
        height: 8vw;
        padding: 1vw 5vw;
        font-size: 3vw;
    }
}