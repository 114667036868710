.active {
    border-bottom: none !important;
}

.logo {
    margin: 50px 0;
}

.our-client {
    margin-top: 130px;
    color: #005082;
    margin-bottom: 10px !important;
    font-weight: 800;
    line-height: 1.5;
}

@media (min-width:320px)and (max-width:425px) {
    .our-client {
        margin-top: 40px;
    }

    .logo {
        margin-bottom: -57px;
    }

    .logo img {
        width: 80px;
    }

}

.ClientsScroller {
    max-width: 200px;
    padding: 10px;
    border-radius: 15px;
}

.ClientsScroller img {
    width: 100%;
}

.SliderbackgroundJitendra {
    /* background: #BFE5EF; */
    /* padding: 10px 0; */

    background: #BFE5EF;
    padding: 50px 0;
    height: 208px;
}
.clientHeading{
    display: none !important;
}
.img-bg{
    padding: 0 !important;
    margin: 0;
}

@media screen and (max-width: 1400px) {
    .SliderbackgroundJitendra {
        background: #BFE5EF;
        padding: 3.5vw 0;
        height: 15vw;
    }
}
@media screen and (max-width: 992px) {
    .SliderbackgroundJitendra {
        background: #BFE5EF;
        padding: 2.5vw 0;
        height: 20vw;
    }
}