.why-texts {
    text-align: center;
    margin-bottom: 25px;
}

.why-main {
    /* padding-top: 20px; */
    margin-top: 150px;
}

.boxes {
    /* gap: 40px; */
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boxes-2 {
    display: flex;
    justify-content: center;
}

.why-h2 {
    font-family: 'Lato', sans-serif;
    font-size: 34px;
    margin-bottom: 15px;
    margin-top: 30px !important;
    color: rgba(3, 166, 202, 1);
    font-weight: 600;
    line-height: 36px;
    /* letter-spacing: 0.05em; */
    text-align: center;

}

.why-texts2 {
    margin: auto;
    text-align: center;
    /* padding: 0 150px; */
    width: 80%;
}

.why-h1 {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato', sans-serif;
    font-size: 48px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 58px;
    /* letter-spacing: 0.05em; */
    text-align: center;

}

.why-p {
    font-family: 'Lato', sans-serif;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 22px;
    /* letter-spacing: 0.05em; */
    text-align: center;
    /* padding: 40px; */
    /* width: 80%; */
    color: rgba(51, 51, 51, 1);
}

.why-p2 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    /* letter-spacing: 5%; */
    letter-spacing: 0.05em;
    text-align: center;
    /* padding: 40px; */
    /* width: 80%; */
    color: rgba(51, 51, 51, 1);
}

.why-p3{
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    /* letter-spacing: 5%; */
    letter-spacing: 0.05em;
    text-align: center;
    /* padding: 40px; */
    width: 80%;
    margin: auto;
    color: rgba(51, 51, 51, 1);
    /* line-height: 30px; */
}

.why-p3 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 5%;
    color: rgba(51, 51, 51, 1);
    /* padding: 0 40px; */
    text-align: center;
    width: 80%;

}


.why-R1 {
    display: flex;
    /* align-items: center; */
    gap: 40px;
    width: 90%;
    margin: 0 40px;
    justify-content: center;

}



.why-R2 {
    display: flex;
    width: 90%;
    margin: 40px;
    /* align-items: center; */
    justify-content: center;
    gap: 40px;
}

.why-R3 {
    display: flex;
    width: 90%;
    margin: 40px;
    /* align-items: center; */
    justify-content: center;
    gap: 40px;
}

.box-1 {
    background-color: white;
    width: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;
    /* border: 2px  solid rgba(0, 0, 0, 0.25); */
}


.box-h {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    /* letter-spacing: 0.02em; */
    text-align: left;
    color: rgba(9, 81, 130, 1);
}

.card-title {
    color: rgba(9, 81, 130, 1);
}

.box-p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(111, 108, 108, 1);

}
.web{
    margin-top: 30px;
}
.web-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin-bottom: 80px;
    /* margin-top: 30px; */
}

.box-1:hover .box-h,
.box-1:hover .box-p {
    color: white !important;
}

.box-1:hover{
    background-color: rgba(9, 81, 130, 1);
}


@media screen and (max-width:992px) {
    .why-texts2 {
        padding: 0 5vw;
        width: 100%;
    }

    .why-h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .why-h2 {
        font-size: 30px;
    }

    .why-p2{
        font-size: 16px;
    }

    .why-p3{
        font-size: 16px;
    }

    .box-h {
        font-size: 16px;
        line-height: 20px;
    }

    .box-p {
        font-size: 13px !important;
        line-height: 18px;
    }

    .w-box {
        padding: 10px !important;
    }

    .web-2 {
        margin-bottom: 40px;
    }
    .box-1{
        padding: 20px;
    }

    
}


@media screen and (max-width:600px) {
    .why-h1 {
        font-size: 18px;
        margin: 0;
        margin-bottom: 10px;
        line-height: 20px;

    }

    

    .why-texts2 {
        padding: 0;
        line-height: 12px;
    }

    .boxes {
        margin-top: 20px;
    }

    .why-texts {
        margin-bottom: 20px;
        
    }

    

    .why-h2 {
        font-size: 18px;
        line-height: 10px;
        margin-top: 0 !important ;
    }

    .why-p {
        padding: 0;
        font-size: 12px !important;
        line-height: 14px;
    }

    .why-p2 {
        font-size: 12px !important;
        line-height: 15px;
        margin-bottom: 15px;
    }

    .why-R1 {
        margin: 0;
        width: auto;
        gap: 20px;
        padding: 0 20px;
        margin-bottom: 15px;
    }

    .why-R2 {
        margin: 0;
        width: auto;
        gap: 20px;
        padding: 0 20px;
    }



    .why-R3 {
        margin: auto;
        margin-top: 20px;
        gap: 20px;
        padding: 0 20px;
    }

    .box-1 {
        padding: 12px;
    }

    .box-h {
        font-size: 12px;
        line-height: 12px;
    }

    .box-p {
        font-size: 10px !important;
        line-height: 15px;
    }

    .boxes-2 {
        margin-bottom: 20px;
    }

    .web {
        margin-top: 30px;
    }

    .web-2 {
        margin-top: 40px;
        margin-bottom: 40px;

    }

    .why-p3 {
        font-size: 12px !important;
        line-height: 15px;
        padding: 0;
    }

    .why-main {
        padding: 0;
    }

}

@media screen and (max-width:576px) {
    .why-R1,
    .why-R2,
    .why-R3{
        flex-direction: column;
        width: 100%;
    }
    .box-1 {
        width: 100%;
    }
    .why-p3{
        width: 100%;
    }
    .box-p,
    .box-h{
        text-align: center !important;
    }

.why-main{
    margin-top: 50px;
}

}