.lakeH-Faq-wrapper {
  background-color: transparent;
}
.lakeH-acc-c {
  /* border-radius: 0 0 0 16px !important; */
  overflow: hidden;
  border-color: #eaeaea #eaeaea #dddddd #eaeaea;
  border: none;
  border-bottom: 2px solid #eaecee !important;
  /* border-bottom-width: 2px; */
  /* margin-bottom: 3px; */
  background-color: transparent;
}
.lakeH-acc-h .collapseButton {
  color: #1a1a1a;
  text-decoration: none;
  text-align: left !important;
  display: flex;
  justify-content: space-between !important;
  font-size: 16px;
}
.lakeH-acc-h .collapseButton:hover {
  /* color: red; */
  color: #1a1a1a;
  text-decoration: none;
}

.lakeH-acc-d {
  border: none;
  color: grey;
}

/* .lakeH-acc-c .card-header {
} */
.btn:focus {
  outline: none !important;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
  box-shadow: none;
}

.panel,
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border: none;
}
:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
.card-header {
  border-bottom: none !important;
  background-color: transparent !important;
}

.accordion .card-header h2 .collapsed::after {
  font-family: "FontAwesome";
  content: "\f067";
}

.accordion .card-header h2 ::after {
  font-family: "FontAwesome";
  content: "\f068";
}
.faq-readme-btn {
  float: right;
  font-size: 12px;
  display: flex;
  justify-items: right !important;
  min-width: 0px !important;
  color: blue;
  min-height: 30px!important;
  /* transition: 0.3s all ease-in-out; */

  /* background-color: red; */
}
.faq-readme-btn :hover {
  color: blue !important;
}
#collapsebtn-01 {
  border: 2px solid white;
  outline: none;
  color: white;
  background-image: linear-gradient(150deg, #91badd,#02377c) !important;
  margin-bottom: 10px;
  padding: 12px;
  transition:border 0.5s ease;
}

#collapsebtn-01:hover {
  background: white !important;
  color: #005082 !important;
  border: 2px solid #005082;
  font-weight: 600;
}

/* Css for Page responsiveness */

@media only screen and (max-width: 992px) {
  .container-h{
    height: auto;
  }
  .lakeH-Faq-wrapper{
    display: block !important;
    /* height: 120vh; */
    height: auto;
  }
  .FAQ-row-wrapper {
    flex-direction: column-reverse;
  }
  #hubspotForm {
    margin-top: 22px;
  }
  /* .lakeH-Faq-wrapper{
    margin-top: -60px;
  } */
}

@media only screen and (max-width: 576px) {
  .lakeH-Faq-wrapper{
    /* height: 125vh; */
    height: auto;
    margin-top: -70px;
  }
}
@media only screen and (max-width: 425px) {
  .lakeH-acc-h .collapseButton {
    font-size: 14px !important;
  }
  .lakeH-acc-d {
    font-size: 12px !important;
  }
  .lakeH-Faq-wrapper{
    /* height: 135vh; */
    height: auto;
  }
}

/* Faq section css end here */
