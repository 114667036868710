.cities-sub-wrapper {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: #ffffff99;
  border-radius: 25px;
  box-shadow: 2px 2px 10px 2px #00000020;
  color: #000;
  width: 245px;
  margin: auto;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  padding: 20px;
  margin-top: 22px;
  text-align: center;
  list-style: none;
  transition: 0.1s all ease-in-out;
  /* z-index: 99; */
}
.cities-sub-wrapper:hover {
  background-color: #93deec70;
  cursor: pointer;
}
.cities-sub-wrapper:hover a {
  color: black;
}
.cities-wrapper {
  padding-right: 10px;
  padding-left: 10px;
}
