.ConnectOurExperts-container {
    background-color: #E1F1FA;
    margin-top: 100px;
    padding: 78px 15px;
}

.ConnectOurExperts-text p {
    padding: 0 25%;
}

.person_text h2 {
    color: var(--high, #095182);
    font-size: 26px;
    font-weight: 600;
}

.ConnectOurExperts-person{
    gap: 35px;
    margin-top: 50px;
}
.person_text button {
    display: flex;
    padding: 12px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: box-shadow 0.5s ease!important;
    border-radius: 30px 10px;
    background: #095182;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}
.person_text button:hover {
    padding: 10px 23px;
    color: #005082!important;
    background: white !important;
    border: 2px solid #005082;
    box-shadow: 5px 5px 5px 0px #005082;
    font-weight: 600;
}

.person_img {
    width: 150px;
    height: 150px;
    background-color: gray;
    border-radius: 0 30px;
}


.ServiceProblemSolving-text p{
    padding: 0 33% !important;
}

@media screen and (max-width: 992px) {
    .ServiceProblemSolving-text p{
        padding: 0 15% !important; 
    }
}


@media screen and (max-width: 576px) {
    .ConnectOurExperts-text p {
        padding: 0 5%;
    }
    .ConnectOurExperts-person{
        flex-direction: column;
        gap: 35px;
        margin-top: 50px;
    }
    .person_text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ServiceProblemSolving-text p{
        padding: 0 5% !important; 
    }
}