.home-main {
  margin-top: 140px;
  /* margin: 40px auto 0 auto; */
  /* padding: 50px 120px; */
  display: flex;
  /* max-width: 1400px; */
  /* position: relative; */
  /* flex-direction: column; */
}

.home-heading {
  font-family: "Lato", sans-serif;
  font-size: 46px;
  line-height: 57.6px;
  font-weight: 900;
  letter-spacing: 1px;
  margin-top: 50px;
  color: rgba(0,0,0,1);
}

.line {
  width: 140px;
  height: 4px;
  background-color: rgba(3, 166, 202, 1);
  border: 3px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.HI {
  /* width: 100%; */
  /* position: absolute; */
  /* right: 30; */
  margin-left: 50px;
  margin-top: 35px;
  /* padding-bottom: 20px; */
}

.home-p {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: left;

}

.home-btns {
  margin-top: 40px;
  display: flex;
  gap: 15px;
}

.btn-1 {
  /* background-color: rgba(9, 81, 130, 1);
  width: Hug (218px);
  color: white;
  height: Hug (42px);
  top: 552px;
  left: 74px;
  padding: 10px 30px 10px 30px;
  border-radius: 30px 10px 30px 10px;
  gap: 10px;
  margin: 5px;
  text-decoration: none !important; */

  display: flex;
  width: 226.433px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px 10px;
  background: var(--high, #095182);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #D9D9D9;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
  text-decoration: none !important;
}

.btn-1:hover {
  background-color: white;
  color: rgba(9, 81, 130, 1) !important;
  border: 1px solid var(--high, #095182);
}



.btn-2 {
  /* width: Hug (217px);
  background-color: rgba(255, 255, 255, 1);
  height: Hug (42px);
  color: rgba(9, 81, 130, 1);
  top: 552px;
  left: 306px;
  padding: 10px 30px 10px 30px;
  border-radius: 30px 10px 30px 10px;
  border: 1px solid color(rgba(9, 81, 130, 1));
  gap: 10px;
  margin: 5px;
  text-decoration: none !important; */

  display: flex;
  width: 225.394px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px 10px;
  border: 1px solid var(--high, #095182);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--high, #095182);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
  text-decoration: none !important;
}

.btn-2:hover {
  background-color: rgba(9, 81, 130, 1);
  color: white !important;
}

.home-img {
  display: flex;
}

@media screen and (max-width: 992px) {
  .home-img {
    display: none;
  }

  .home-main {
  margin-top: 70px;
  }

  .home-heading {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {

  .btn-1,
  .btn-2 {
    width: 30vw;
    padding: 1vw 2vw;
    font-size: 13px !important;
  }


}

@media screen and (max-width: 600px) {
  .home-main {
    /* padding: 0 20px 20px 20px; */
    margin-top: 40px;
  }

  .btn-1 {
    font-size: 13px !important;
  }

  .btn-2 {
    font-size: 13px !important;
  }

  .home-heading {
    font-size: 24px;
    line-height: 30px;
  }

  .home-p {
    line-height: 17px;
    font-size: 14px;
  }

  .home-btns {
    display: flex;
  }

  /* .btn-1 {
    padding: 10px;
  }

  .btn-2 {
    padding: 10px;
  } */
}

@media screen and (max-width: 576px) {

  .btn-1,
  .btn-2 {
    width: 40vw;
    padding: 1vw 2vw;
    font-size: 2.5vw;
  }

  .home-main{
    padding: 6vw 0 0 0;
  }
}