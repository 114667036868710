/* tech stack css start from here  */
.ots-heading {
    background-color: #005082;
    /*light blue*/
    padding: 4px 0px;
    font-weight: 600;
    color: white;
    font-size: 17px;
    width: 90%;
    border-radius: 14px;
  }
  
  .ots-heading div {
    font-weight: 600;
    color: white;
    font-size: 20px;
  }
  
  .ots-tech-items {
    display: flex;
    flex-direction: column;
    /* box-shadow: 0px 0px 6px 2px rgb(193 193 193); */
    box-shadow: 0 3px 6px 1px #bfebfa;
    border-radius: 16px;
    border: none;
    align-items: center;
    margin: 0px 5px;
    padding: 15px 0px;
    margin-top: 8px;
    max-width: 100px !important;
    /* height: 129px; */
    /* width: 90% !important; */
  }
  
  .ots-tech-items .ots-tech-items-01 {
    max-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: auto;
    /* box-shadow: 0 3px 6px 1px #bfebfa;
    border-radius: 50%; */
  }
  
  .ots-tech-items div {
    font-size: 12px;
    font-weight: 600;
    color: rgb(107, 107, 107);
  }
  
  .ots-tech-items-endborder {
    /* border: 1px solid #005082; */
    background-color: #005082;
    /* padding: 10px 0px; */
    height: 6px;
    width: 100%;
  
    /* margin-top: 25px; */
  }
  
  .ots-tech-items-wrapper {
    /* border-right: 1px dotted grey; */
    /* background-color: red; */
    /* margin-top: 25px; */
  
    /* padding: 12px 0px; */
    width:90%;
    height: 100% !important;;
  }
  
  .border-d-right {
    border-right: 1px dotted grey;
  }
  
  .border-d-left {
    border-left: 1px dotted grey;
  }
  
  .border-d-xaxis {
    border-left: 1px dotted grey;
    border-right: 1px dotted grey;
  }
  
  .ots-tech-items:hover {
    background-color: rgb(191, 235, 250, 0.3);
    cursor: pointer;
  }
  
  .ots-tech-items-endborder-000 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ourtechstack-sub-wrapper {
    align-items: flex-end;
    justify-content: center;
  }
  
  @media only screen and (max-width: 1024px) {
    .ots-tech-items-endborder-000 {
      flex-direction: column-reverse;
      /* margin-top: 44px; */
    }
  
    .ourtechstack-sub-wrapper {
      align-items: flex-start;
    }
  
    .ots-tech-items {
      margin: 0px 5px;
      padding: 5px 0px;
      margin-top: 12px;
      font-size: 512px;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .ots-tech-items-wrapper2 {
      border-right: 0px !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .ots-tech-items-wrapper2 {
      border: 0px !important;
    }
  }
  
  @media only screen and (max-width: 567px) {
    .ots-tech-items-wrapper {
      border: 0px !important;
    }
  
    .ots-tech-items {
      display: flex;
      flex-direction: column;
      /* box-shadow: 0px 0px 6px 2px rgb(193 193 193); */
      box-shadow: 0 3px 6px 1px #bfebfa;
      border-radius: 16px;
      border: none;
      align-items: center;
      margin: 0px 5px;
      padding: 10px 0px;
      margin-top: 8px;
      max-width: 85px !important;
      /* height: 129px; */
      /* width: 90% !important; */
    }
  }