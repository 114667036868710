.IdelLeads-DriveMetrics-wrapper {
  margin-top: 90px;
}
.IdelLeads-DriveMetrics-container2-wrapper {
  /* margin-top: 22px; */
}
.IdelLeads-DriveMetrics-wrapper-00 {
  background-color: #bfebfa;
}
.IdelLeads-DriveMetrics-container1-wrapper p {
  /* color: #8f96a6; */
}

.IdelLeads-DriveMetrics-container1-wrapper {
  background-color: #0c6ca8;
  border-radius: 40px;
  margin-top: -90px;
  z-index: 111;
  padding: 40px 0px;
  color: white;
}

.IdelLeads-Drive-heading h2 {
  font-weight: 600;
  color: #005082;
  /* margin-bottom: 24px !important; */
}
.IdelLeads-matrix-slider p {
  font-size: 26px;
  font-weight: 500;
}
.IdelLeads-matrix-slider p span {
  font-weight: 800;
}
.Idealeads-Matric-Icon {
  width: 45px;
  padding-bottom: 10px;
  text-align: center;
  margin: auto;
}
.Idealeads-Matric-Icon img {
  width: 100%;
}

.IdelLeads-DriveMetrics-container1-p{
  font-size: 46px;
  line-height: 62px;
}

@media only screen and (max-width: 768px) {
  .IdelLeads-DriveMetrics-container1-wrapper {
    background-color: #0c6ca8;
    border-radius: 40px;
    margin-top: -90px;
    z-index: 111;
    padding: 30px 0px;
    color: white;
  }

  .IdelLeads-DriveMetrics-container1-p{
    font-size: 36px !important;
    line-height: 62px;
  }
}

@media only screen and (max-width: 425px) {
  .IdelLeads-DriveMetrics-container1-wrapper {
    background-color: #0c6ca8;
    border-radius: 40px;
    margin-top: -90px;
    z-index: 111;
    padding: 10px 0px;
    color: white;
  }

  .IdelLeads-DriveMetrics-container1-p{
    font-size: 30px !important;
    line-height: 62px;
  }
}

@media only screen and (max-width: 375px) {
  .IdelLeads-DriveMetrics-container1-wrapper {
    background-color: #0c6ca8;
    border-radius: 40px;
    margin-top: -90px;
    z-index: 111;
    padding: 10px 0px;
    color: white;
  }

  .IdelLeads-DriveMetrics-container1-p{
    font-size: 26px !important;
    line-height: 62px;
  }
}

@media only screen and (max-width: 320px) {
  .IdelLeads-DriveMetrics-container1-wrapper {
    background-color: #0c6ca8;
    border-radius: 40px;
    margin-top: -90px;
    z-index: 111;
    padding: 10px 0px;
    color: white;
  }

  .IdelLeads-DriveMetrics-container1-p{
    font-size: 26px !important;
    line-height: 62px;
  }
}