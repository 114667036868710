/* Carriers Page Styles */

.cp-ma-00 {
  position: relative;
}

.cp-ma-00-01 {
  position: absolute;
  top: 50%;
  left: 0%;
  padding-left: 200px;
  transform: translate(-0%, -50%);
}

.cp-ma-01 {
  background-color: #ff9329;
  color: white;
  border: 0px;
  padding: 10px 20px;
  border-radius: 10px;
}

.cp-ma-00-img {
  width: 400px;
  height: 400px;
}

.cp-ma-02-border {
  box-shadow: 0px 0px 6px 1px gray;
  padding: 20px;
  border-radius: 20px !important;
  transition: all 1s ease-in-out;
  background-color: white;
}

.cp-ma-02-border:hover {
  box-shadow: 0px 0px 15px 2px gray;
  transition: all 1s ease-in-out;
}

.cp-ma-02-button {
  background-color: #ff9329;
  font-size: 12px;
  padding: 10px;
  border: 0px;
}

/* Browser  Extension Page  Css start Here*/
.be-h-0 {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 0px;
}
.be-h-0 div {
  color: grey;
  font-size: 18px;
}

/* Css for Stop Candly SCroll bar  */
/* ._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv {
  margin-bottom: 0px !important;
} */
/* CAndly Css end here */

/* Browser  Extension Page  Css end Here*/
