body {
  font-family: "Lato";
  overflow-x: hidden;
}

.banner-content {
  z-index: 99;
  padding: 40px;
  text-align: left;
  color: #000;
  /* max-width: 55%; */
  background: #ffffff99;
  backdrop-filter: blur(5px);
  border-radius: 25px;
  box-shadow: 2px 2px 10px 2px #00000020;
}

.banner-content h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.banner-content .underline::after {
  left: 50%;
}

.bg-colour {
  background-color: #bfebfa50;
  padding: 30px 0;
}

.banner-content h1 {
  font-weight: 600;
  color: #005082;
  margin-bottom: 8px !important;
  line-height: 1.3;
}

.banner-content p {
  font-size: 18px !important;
  margin-bottom: 24px !important;
}

.outing {
  margin-top: 20px;
  padding-bottom: 120px;
}

.outing h2 {
  margin-top: 80px;
}

.outing-colum {
  margin-top: 30px;
}

.outing img :hover {
  box-shadow: 10px 10px 10px 10px #00508220;
}

.imag0 {
  min-width: 260px;
}

.teams-member-colum {
  /* margin-top: -50px; */
}

.member-card:hover img {
  border: transparent;
}

.teamMemberDetail {
  margin-top: 10px;
}

.teamMemberDetail p {
  margin: 0 !important;
}

.member-card {
  transition: all 0.3s;
  border-radius: 15px;
  border: 1px solid transparent;
  text-align: center;
  /* width: auto !important; */
  width: 150px !important;
  margin-bottom: 20px;
  padding-bottom: 20px;
  box-shadow: 1px 1px 10px 1px #00508220;
  border: 1px solid #00508220;
  background-color: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.member-card:hover {
  box-shadow: 1px 20px 80px 1px #000a0f20;
}

.member-card h4 {
  margin-bottom: -3px;
  margin-top: 3px;
  font-size: 14px;
}

.member-card p {
  font-size: 12px;
}

.member-card img {
  text-align: center;
  border-radius: 15px 15px 0 0px;
  width: 100%;
  height: 150px;
  background-color: #fff;
}

.headings {
  font-weight: 600;
  color: #005082;
  margin-bottom: 18px;
  line-height: 1.3;
  margin-top: 35px;
}

.heading-teams {
  text-align: center;
  margin: 30px;
}

.heading-aboutus {
  margin-bottom: 25px;
}

.heading-aboutus h2 {
  color: #005082;
  margin-bottom: 10px !important;
  text-align: center;
}

.heading-aboutus h4 {
  text-align: center;
  margin-bottom: 12px;
}

.heading-aboutus p {
  max-width: 90%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
}

.archive-teams {
  padding: 20px;
}

/* .archive-teams img {
    width: 120px;
} */

.archive-teams h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.archive-teams p {
  text-align: center;
  font-size: 16px;
}

.industries {
  text-align: center;
  padding-bottom: 10px;
}

.each-team {
  /* margin-top: 140px; */
  /* margin-top: 180px;
  margin-bottom: 140px; */
}

.industries img {
  /* width: 80px; */
  margin: 20px;
}

.industries h3 {
  font-size: 16px;
  font-weight: 600;
}

.outing-colum img {
  margin-bottom: 20px;
}

.wi-header {
  background-repeat: no-repeat;
  height: 700px;
}

.container-fluid {
  padding: 0 !important;
}

.border-1 {
  border-bottom: 4px solid #2196f3;
  border-radius: 10px;
  width: 80px;
}

.header-text {
  max-width: 495px;
}

.components-bg {
  padding-top: 20px;
  height: 100vh;
  padding-bottom: 30px;
  background-image: url("../../../images/teams/bg0.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.components-bg0 {
  background-color: #bfebfa;
  padding-top: 20px;
  padding-bottom: 30px;
}

.components-bg p {
  margin: auto;
}

.our-services0 {
  height: 500px;
  padding-bottom: 30px;
}

.our-services {
  height: 100%;
}

.image-l-r {
  height: 500px;
  width: 400px;
}

.banner-main {
  margin-top: 50px;
  margin-bottom: -50px;
  width: 100px;
}

.border-image {
  width: 75px;
  margin: 0 auto 16px;
  border-radius: 50px;
  box-shadow: 4px 4px 10px 4px #bfebfa50;
  padding: 8px;
  border: 1px solid #bfebfa;
  background: #ffffff60;
  transition: all 0.3s;
}

.border-image:hover {
  box-shadow: 4px 4px 10px 4px #00508220;
  border: 1px solid #00508220;
}

.member-card0 {
  transition: all 0.3s;
  border-radius: 15px;
  border: 1px solid transparent;
  text-align: center;
  width: auto !important;
  /* margin-bottom: 50px; */
  /* padding-bottom: 20px; */
  box-shadow: 1px 1px 10px 1px #00508220;
  border: 1px solid #00508220;
  background-color: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px; */
}

.member-card0:hover {
  box-shadow: 1px 20px 80px 1px #000a0f20;
}

.member-card1 {
  transition: all 0.3s;
  border-radius: 15px;
  border: 1px solid transparent;
  text-align: center;
  width: 350px !important;
  height: auto !important;
  margin-bottom: 0px;
  padding-bottom: 20px;
  box-shadow: 1px 1px 10px 1px #00508220;
  border: 1px solid #00508220;
  background-color: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

/* .member-card1:hover {
  box-shadow: 1px 10px 20px 1px #000a0f20;
} */

.icon:hover {
  color: #005082;
}

.icon:active {
  color: #005082;
}

.icon:link {
  color: #005082;
}

.icon:visited {
  color: #005082;
}

@media only screen and (max-width: 1200px) {
  .our-services0 {
    height: 550px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  /* .member-card img {
    height: 165px;
  } */

  .our-services0 {
    height: 500px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .our-services0 {
    height: 700px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .member-card {
    float: left;
  }

  /* .member-card img {
    width: 237px;
    height: 237px;
  } */

  .wi-header .col-3 {
    display: none !important;
  }

  .our-services0 {
    height: 650px;
    padding-bottom: 30px;
  }

  .components-bg .banner-content {
    backdrop-filter: blur(25px) !important;
  }
}

@media screen and (max-width: 575px) {
  .member-card0 {
    padding-bottom: 10px;
  }

  .member-card {
    padding-bottom: 10px;
  }

  .our-services0 {
    height: 500px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 360px) {
  .our-services0 {
    height: 600px;
  }
}





/* New team Component  */

.member-card_Img {
  width: 165px;
  height: 200px;
  /* background-color: rgba(0, 0, 0, 0.2); */
  margin: auto;
  border-radius: 15px;
  margin-top: 42px;
}

.member-card_Img img {
  width: 100%;
  height: 100%;
}

.teamMemberDetail {
  margin-top: 0 !important;
  padding: 28px 20px 40px 20px;
}

.teamMemberDetail h4 {
  color: var(--high, #095182);
  text-align: center;
  /* font-family: Lato; */
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.4px;

  margin-bottom: 10px;
}

.teamMemberDetail p {
  color: #000;
  text-align: center;
  /* font-family: Lato; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.14px;
}
.New_Team_card{
  row-gap: 50px;
}
.New_Team{
  border-bottom: 5px solid rgba(9, 81, 130, 1);
  border-radius: 20px !important;
}
.homepage-heading h2{
  text-transform: capitalize !important;
}
.homepage-heading p {
  max-width: 45% !important;
}
.New_Team:hover{
  border: 1px solid rgba(9, 81, 130, 1);
  border-bottom: 5px solid rgba(9, 81, 130, 1);
}
