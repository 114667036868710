/* .main{
  position: relative;
} */
#add-chrome{
  background: rgba(83, 25, 251, 1);
  color: white;
  border: none;
  width: 180px;
  height: 35px;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;
  /* font-family: "Poppins"; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}
.p-div{
  position: relative;
  margin-top: 20px;
}
.errorImg{
  position: absolute !important;
  bottom: 38px;
}
.podspiria-header {
  /* width: 98vw; */
  /* height: 18.5vh; */
  display: flex;
}
.mob-div {
  display: none;
}
.main-h {
  /* font-family: 'Poppins',sans-serif; */
  font-size: 45px;
  /* font-weight: 900; */
  line-height: 52px;
  /* letter-spacing: 0.02em; */
  text-align: left;
  color: rgba(83, 25, 251, 1);
  margin-bottom: 30px;
}
.podImg {
  width: 60%;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}
#PodspiriaLogo {
  width: 35%;
  /* height: 79px; */
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
  /* padding: 1%; */
  /* padding-left: 23px; */
}
.sideHeader {
  /* width: 50vw; */
}
#rectangle {
  right: 0%;
  position: absolute;
  top: -5%;
}
.background {
  width: 379px;
  height: 269px;
  border-radius: 70px;
  background-color: rgba(83, 25, 251, 1);
  align-items: flex-end;
  transform: rotate(166deg);
  margin-top: -25%;
  flex: 1 1;
  text-align: right;
  right: -50%;
  position: relative;
}
.buttons {
  right: 0;
  position: absolute;
  top: 6%;
  width: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#Login {
  background: none;
  border: none;
  color: white;
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  cursor: pointer;
  padding-right: 25px;
}
#Login:hover {
  color: black;
}
#GetStarted {
  width: 58%;
  height: 37px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 1);
  color: rgba(83, 25, 251, 1);
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
#GetStarted:hover {
  background-color: black;
  color: white;
}

.Pod-Sections {
  display: flex;
  margin-top: 100px;
}
.RightSection {
  width: 40vw;
  /* padding-left: 4%;
    text-align: justify;
    padding-top: 5%;
    padding-right: 4%; */
}
h1 {
  color: rgba(83, 25, 251, 1);
  /* font-family: "Poppins"; */
}

.home-text {
  margin-top: 20px;
  /* font-family: Poppins; */
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  /* letter-spacing: 0.02em; */
  text-align: left;
  color: rgba(51, 51, 51, 1);
}
.p-tag {
  text-align: center;
  /* font-family: "Poppins"; */
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: justify;
  color: rgba(100, 109, 116, 1);
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -3%; */
}
.p-error {
  text-align: center;
  /* font-family: "Poppins"; */
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: justify;
  color: rgba(100, 109, 116, 1);
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
#inputLink {
  height: 45px;
  width: 70%;
  padding: 4px;
  color: black;

  position: relative;
  top: 5%;
  display: flex;
}
#InputPurpose {
  top: 50px;
  position: relative;
}

#purposeButton {
  height: 4.5vh;
  width: 19vw;
  border: none;
  border-radius: 7px;
  color: white;
  background: rgba(83, 25, 251, 1);
}
.purposeOptions {
  height: 24vh;
  overflow-y: scroll;
  padding-top: 5px;
}
.Purposedropdown-options {
  margin-bottom: 0px;
  display: grid;
  justify-content: center;
  align-items: center;
  /* font-family: "Poppins"; */
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(100, 109, 116, 1);
  cursor: pointer;
  overflow-y: scroll;
  height: 25vh;
  cursor: pointer;
}

.startbuttons {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* align-items: center; */
  position: relative;
  top: 5%;
  left: -2.5%;
}
.dropdown {
  appearance: none;
  background-color: white;
  border: none;
  padding: 8px;
  font-size: 14px;
  color: black;
}

#Dropbutton {
  background: rgba(83, 25, 251, 1);
  color: white;
  border: none;
  border-radius: 8px;
  height: 35px;
  width: 140px;
  font-size: 15px;
}
#Dropbutton.active {
  background-color: white;
}
#DropSymbol {
  font-weight: bold;
  padding-left: 7px;
}
#DropSymbol2 {
  font-weight: bold;
  padding-left: 7px;
  color: rgba(83, 25, 251, 1);
}

#Start {
  background: rgba(83, 25, 251, 1);
  color: white;
  border: none;
  width: 160px;
  height: 35px;
  margin-left: 12px;
  border-radius: 8px;
  cursor: pointer;
  /* font-family: "Poppins"; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}
#Start:hover {
  background: rgba(44, 0, 173, 1);
}
.LeftSection {
  width: 47vw;
  display: flex;
  justify-content: center;
}
#SideMan {
  /* width: 36vw; */
  /* top: 6%; */
  width: 75%;
  /* position: relative; */
}

.ErrorPopup {
  /* position: absolute;
  top: 54%; */
  display: none;
}

#Emailpopup {
  width: 465px;
  height: 270px;
  position: fixed;
  top: 33%;
  left: 33%;
  background: #e3e3e3;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(222, 226, 230, 1);
  padding: 10px;
  justify-content: center;
  display: grid;
  border-radius: 10px;
  display: none;
}
#CrossPopup {
  position: absolute;
  display: block;
  right: 2%;
  width: 12px;
  top: 3%;
  cursor: pointer;
}

.h3-tag {
  padding: 1px;
  /* font-family: "Poppins"; */
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(83, 25, 251, 1);
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#EmailInput {
  /* width: 410px; */
  background: white;
  border: 1px solid grey;
  border-radius: 6px;
  padding: 0px 6px 0px 6px;
  height: 40px;
}

#Thanks {
  /* width: 423px; */
  border: none;
  border-radius: 6px;
  height: 40px;
  background: rgba(83, 25, 251, 1);
  color: rgba(245, 245, 245, 1);
  font-weight: 600;
  /* font-family: "Poppins"; */
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
}
#Thanks:hover {
  background-color: rgba(44, 0, 173, 1);
  cursor: pointer;
}
#sucessfull {
  position: fixed;
  top: 33%;
  right: 27%;
  display: none;
}
#crossSuccesfull {
  position: relative;
  right: 8%;
  width: 20px;
  height: 22px;
  top: -160px;
  cursor: pointer;
}

/* For webkit-based browsers (Chrome, Safari) */
/* ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:  rgba(83, 25, 251, 1);
  } */

/* For Firefox */
/* .scrollbar-container {
    scrollbar-color:  rgba(83, 25, 251, 1);
  } */
.StepsButton {
  background-color: rgba(83, 25, 251, 1);
  color: #ffffff;
  border-radius: 20px 10px 15px 5px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
}

.mainStepDiv {
  display: flex;
  margin-top: 100px;
  /* padding: 10px; */
}

.subStepDiv {
  /* display: grid; */
  margin: 13px 12px 12px 10px;
  /* justify-content: center; */
  padding: 0px 0px 0px 27px;
}
/* .subStepDiv div{
    justify-content: space-between;
    align-items:center ;
    margin-top: 2px;
  display: flex;
    flex-wrap: wrap;
    
  } */
.subStepDiv1 {
  align-items: center;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}
.subStepDiv2 {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-bottom: 20px;
  align-items: center;
  
}

.subStepDiv3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.subStepDiv button {
  height: 86px;
  width: 91px;
  table-layout: fixed;
  border-collapse: collapse;
  border: hidden;
}
.StepsSpan {
  /* color: #333; */
  color: rgba(51, 51, 51, 1);
  text-align: center;
  /* font-family: "Poppins"; */
  font-size: 16px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  justify-content: center;
}

/* .three{
    margin-left: 85px;
  } */

/* .two{
    margin-left: 40px; */
/* } */

/* .one{
    margin-left: 60px;
  } */

.free {
  margin-top: 30px;
  margin-left: 14px;
}

.free-text {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(3, 87, 1, 1);
}

.hr-tag {
  border: 0;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  margin-bottom: 20px;
  margin-top: 20px;
}
.btn1 {
  font-size: 25px;
  background-color: rgba(83, 25, 251, 1);
  /* padding: 10px; */
  width: 80px;
  height: 80px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px 25px 25px 0;
  color: white;
}

.content > th {
  font-size: 20px;
}
.content2 > th {
  font-size: 20px;
}

#popup1 {
  position: fixed;
  top: 33%;
  right: 27%;
  display: flex;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #e3e3e3;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
 
display: none;
  flex-shrink: 0;
}

.popup {
  padding: 29px;

  gap: 8px;
  display: -webkit-inline-box;

  border-radius: 5px;
}
.popup h2 {
  color: var(--primary-purple, #5319fb);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.p-tag-popup {

  color: #4f4f4f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
    max-width: 35vw;
}
.popup .closepopup {
  color: #5319FB;
 
  font-size: 1.5rem;
    /* font-weight: 700; */
    background: none;
}
.crossPopup{
  position: absolute;
    display: block;
    right: 2%;
    width: 20px;
    top: 3%;
    cursor: pointer;
}
.left-content {
    position: relative;
    /* top: 2px; */
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.right-content{
  gap: 10px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 500px) {
  .RightSection {
    width: auto;
  }
  .ErrorPopup{
    top: 30%;
  }
  .errorImg{
    width: 50vw;
  }
  .startbuttons {
    display: unset;
    position: unset;
  }
  .main-h {
    /* font-family: 'Poppins',sans-serif; */
    font-weight: 900;
    line-height: 26px;
    font-size: 20px;

    /* letter-spacing: 0.02em; */
    text-align: left;
    color: rgba(83, 25, 251, 1);
    /* margin-bottom: 30px */
  }
  .Pod-Sections {
    display: none;
  }
  .home-text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  #SideMan {
    display: none;
  }

  #rectangle {
    top: -2%;
    right: -5%;
    width: 50%;
  }
  #PodspiriaLogo {
    width: 50%;
    margin-top: 15px;
  }

  .mob-div {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }

  #inputLink {
    width: 70%;
    padding: 4px;
    color: black;
    height: 7%;
    position: relative;
    top: 5%;
    display: flex;
  }

  .startdiv {
    margin-top: 50px;
  }
  .free {
    margin-bottom: 60px;
  }

  #Start {
    margin-right: 8px;
    margin-left: 0;
    width: 120px;
  }
  .mob-steps {
    gap: 5px;
    display: flex;
    margin-top: 30px;
    /* flex-direction: column; */
    justify-content: space-around;
  }
  /* .mstep-1 .mstep-2 .mstep-3{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    } */

  .StepsSpan3 {
    color: rgba(51, 51, 51, 1);
    text-align: center;
    /* font-family: "Poppins"; */
    font-size: 10px;
    margin-top: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    justify-content: center;
  }

  .subStepDiv3 {
    justify-content: none;
  }

  .btn1 {
    width: 20vw;
    height: 20vw;
  }
  .StepsSpan {
    font-size: 10px;
  }

  #Emailpopup {
    width: 77vw;
    height: 270px;
    position: fixed;
    top: 15%;
    left: 50px;
  }
  #EmailInput {
    width: initial;
    background: white;
    border: 1px solid grey;
    border-radius: 6px;
    padding: 0px 6px 0px 6px;
    height: 40px;
  }
  .h3-tag {
    font-size: 16px;
  }
  #popup1{
    position: fixed;
    top: 33%;
    display: flex;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #e3e3e3;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
    display: none;
    flex-shrink: 0;
    right: 16%;
  }
  .p-tag-popup{
    max-width: 40vw;
  }
}
@media (min-width: 501px) and (max-width: 660px) {
  #Start{
    width: 176px;
    margin-bottom: 9px;
    margin-left: 8px;

  }
}
@media screen and (max-width: 992px) {
  .pod-main {
    display: grid;
  }
  #SideMan {
    top: -20%;
    width: 85%;
  }

  .LeftSection {
    width: 60%;
  }
}
@media  (min-width: 689px) and (max-width: 800px)  {
  .ErrorPopup{
    top:50%
  }
}
@media screen and (max-width:346px){
  #Start{
    margin-bottom: 8px;
  }
  #add-chrome{
    margin-left: 1px;
  }
}
@media (min-width: 501px) and (max-width: 800px) {
  .RightSection {
    width: 76vw;
  }
  .StepsSpan{
    font-size: 12px;
  }
  .main-h{
    font-size: 24px;
  }
  .home-text{
    font-size: 14px;
  }
  .mainStepDiv{
    gap: 10px;
    display: flex;
    margin-top: 26px;
  }
  #Emailpopup{
  width: 300px;
  height: 270px;
  position: fixed;
  top: 33%;
  left: 15%;
  background: #e3e3e3;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(222, 226, 230, 1);
  padding: 10px;
  justify-content: center;
  display: grid;
  border-radius: 10px;
  display: none;
  }
  .h3-tag{
    font-size: 16px;
  }
  #EmailInput{
    width: initial;
  }
}
@media (min-width: 801px) and (max-width: 1000px) {
  .RightSection {
    width: 53vw;
}
.main-h{
  font-size: 36px;
}
#SideMan {
  
  width: 75%;
}


}

