* {
    margin: 0;
    padding: 0;
}

.OurServices-section {
    text-align: center;
}

.OurServices-section {
    margin-top: 100px;
}

.Service_TabWrap {
    padding: 5rem 0;
    background-color: white;
    border-radius: 32px;
    margin-top: 70px;
}

.servicebtn {
    display: flex;
    width: 120px !important;
    height: 40px !important;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    transition: box-shadow 0.5s ease !important;

    border-radius: 25px 5px;
    background: #095182;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2rem auto 0 auto;
}
.servicebtn:hover{
    color: #005082!important;
    background: white !important;
    border: 2px solid #005082;
    box-shadow: 5px 5px 5px 0px #005082;
    font-weight: 600;
}

.OurServices-section h4 {
    /* color: #333 !important; */
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
}

.OurServices-section h2 {
    color: var(--high, #095182);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.OurServices-section p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 12px;
}
.OurServices-text p{
    width: 70% !important;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 12px;
}

.OurServices-tab {
    margin-top: 5rem;
    /* margin-bottom: 8rem; */
}

.OurService-bg-row {
    padding: 0 6rem;
}

.SaaS_Product {
    padding: 1.5rem 3rem 3rem 3rem;
}

.SaaS_Product:hover {
    background: #E1F1FA;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.05);
    padding: 1.5rem 3rem 3rem 3rem;
    border-radius: 25px;
}

.SaaS_Product-img {
    width: 60%;
    background: white;
    border-radius: 50%;
    padding: 1rem;
}

.SaaS_Product-img img {
    width: 100%;
    height: 100%;
}

.SaaS_Product-Content h6 {
    font-size: 28px;
    margin-top: 2rem;
    color: var(--high, #095182);
    font-weight: 700;
    padding: 0 1rem;
    line-height: 1.2;
}

.react-tabs__tab {
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px 15px 50px 15px;
}


.OurServices-heading h6 {
    color: #333;
    text-align: center;
    /* font-family: Lato; */
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    margin-top: 44px;
}

.OurServices-para p {
    color: rgba(51, 51, 51, 1);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 0px;
    /* padding: 0px 40%; */

}

/* .ProblemSolving-Person-text button, */
.Services-button button {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    transition: box-shadow 0.5s ease!important;

    border-radius: 25px 5px;
    background: #095182;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 35px;
}

.ProblemSolving-Person-text button:hover,
.Services-button button:hover {
    color: #005082!important;
    background: white !important;
    border: 2px solid #005082;
    box-shadow: 5px 5px 5px 0px #005082;
    font-weight: 600;
}


.OurServices-para{
    margin-top: 20px;
}
.OurServices-Nthchild{
    display: flex;
    /* height: 90%; */
    margin-top: 88px;
    border-radius: 14px;
    padding: 35px 16px;
    align-items: center;
    gap: 23px;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
}
.OurServices-Nthchild:hover{
    border-color: #005082;
    border-width: 1px;
    border-style: solid;
    box-shadow: 0px 8px 0px 0px #005082;
    padding: 34px 14px;
}

@media only screen and (max-width: 992px) {
    .OurServices-section {
        margin-top: 10vw;
    }

    .OurServices-tab {
        margin-top: 10vw;
        margin-bottom: 12vw;
    }


    
}

@media screen and (max-width: 768px) {
    .OurServices-parent{
        justify-content: center !important;
        /* row-gap: 30px; */
    }
    
}

@media only screen and (max-width: 992px) {
    .OurService-bg-row {
        padding: 0;
    }
}