.faqMainDiv{
    margin-bottom: 130px;
    margin-top: 0;
}



.faqDivPart1{
    color: rgba(83, 25, 251, 1);
        font-family: "Poppins";
        margin-top: 106px;
}
.faqDivPart2 span {
    color: #4b4b4b;
    font-family: "Poppins";

}
.FAQbg {
    display: flex;
   flex-direction: column;
   margin-top: 130px;
   margin-bottom: 20px;
   background-color: white;
   
}
.faqDivPart1{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* margin-top: 80px; */
}

.faqC-h1{
    font-family: 'Lato',sans-serif;
font-size: 30px !important;
font-weight: 600;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: rgba(0, 0, 0, 1) !important;

}

.faq-h2{
    font-family: 'Lato',sans-serif;
font-size: 48px;
font-weight: 900;
line-height: 58px;
letter-spacing: 0.05em;
text-align: left;
color: rgba(9, 81, 130, 1);

}


hr.p{
    border: block;
    height: 2px;
    width: 96%;
    color:#000000
}

.faqDivPart3{
    display: flex;
    flex-direction: column;
    width: 75%;
    justify-content: center;
    align-items: left;
    color: #000;
    margin-top: 0px;
    font-family: "Poppins";
    margin: auto;
    font-size: 11px;
}
.Faq{
margin: 20px 20px 20px 20px;
font-size: 15px;
width: 70%;
}
#FaqIdC{
    display: flex;
    font-family: 'Lato',sans-serif;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        
}



#faqparaC{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    font-family: 'Lato',sans-serif;
    margin-bottom: 30px;
    font-size: 22px !important;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

#FaqIdC button{
  /* margin-left: 90px; */
  justify-content: end;
  align-items: end;
  justify-content: flex-end;
/* background-color: transparent; */
    /* background-repeat: no-repeat; */
    border: none;
    background: none !important;
    cursor: pointer;
    /* overflow: hidden; */
    outline: none;
    font: icon;
}


.faqtitle{
    border-bottom: 1px solid black;
}

#FaqIdC:hover{
    color: rgba(9, 81, 130, 1);
}

.faq:hover{
    color: rgba(9, 81, 130, 1);
}
.activeButton {
    border: 2px solid rgba(9, 81, 130, 1);


}

.faq-text{
    font-family: 'Lato',sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 21.6px;
letter-spacing: 0.02em;
margin-top: 25px;
text-align: center;
width: 70%;
margin-bottom: 40px;
color: rgba(51, 51, 51, 1);

}

.listItem li{
    font-size: 16px;
}
  
@media screen and (max-width:992px) {
    .faqC-h1{
        font-size: 26px !important;

    }
    .faq-h2{
        font-size: 28px;
        margin: 0;
    }
    .faq-text{
        font-size: 17px;
    }
  
    
}

@media screen and (max-width:500px) {
  
.faq-h2{
    font-size: 18px;
    line-height: 16px;
    margin-bottom: 15px;
    text-align: center;
}

.faqC-h1{
    font-size: 16px !important;
    line-height: 20px;
}
    .faq-text{
        font-size: 14px;
        line-height: 14px;
        margin: 0;
    }

   #FaqIdC{
    font-size: 12px;
   }

   #faqparaC{
    font-size: 12px !important;
    line-height: 16px;
   }

   .faqMainDiv{
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
   }

   .faqDivPart1 h2{
    font-weight: 500;
    margin-bottom: 15px;
   }

   .faqDivPart1{
    margin-top: 50px ;
   }

   .listItem li{
    font-size: 12px;
}

.faq-img{
    width: 4vw;
    margin-right: 10px;
}
.FAQbg{
    margin-top: 60px;
}

}
