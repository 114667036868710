.test-main {
    display: flex;
    margin-bottom: 50px;
position: relative;
    margin-top: 160px;
}



.test-btns {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.test-1 {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.back_in {
    display: flex;
}

.test-2 {
    background-color: rgba(226, 249, 255, 1);
    display: flex;
    flex-direction: column;
    padding: 30px;
    max-width: 900px;
    height: 300px;
    
}
/* .test-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
 

.test-3 {
    background-color: rgba(226, 249, 255, 1);
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    gap: 20px;
}
/* .test-slider {
    height: 400px; 
    overflow: hidden;
  } */
.test-slid-btns{
    /* position: absolute; */
    /* left: 0; */
    /* top: 250px; */
    margin-top: 10px;   
}

.left1{
    height: 50px;
    color: rgba(9, 81, 130, 1);
    margin-right: 20px;
}


.right1{
    height: 50px;
    color: rgba(9, 81, 130, 1);
}

.test-h1 {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
}

.test-h2 {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato', sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 58px;
    margin: 20px 0;
}

.test-p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    width: 80%;
    color: rgba(51, 51, 51, 1);
}

.test-cards {
    display: flex;
    width: 65%;
}


.ceo-div {
    display: flex;
    margin-top: 20px;
}

.ceo-name {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato' sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-align: left;

}

.CEO {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(47, 42, 42, 1);

}

.ceo-t {
    padding: 10px 20px;
}

.ceo-idiv{
    display: flex;
    margin-top: 15px;
}


@media screen and (max-width:992px) {
    .test-main{
        flex-direction: column;
        margin-top: 100px;
        gap: 30px;
        align-items: center;
    }
    .test-1{
        width: 100%;
        align-items: center;
    }

    .test-p{
        width: 100%;
        text-align: center;
    }
    .test-2{
        /* margin: auto; */
        width: 55vw;
        margin-top: 30px;
    }

    .test-slid-btns{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media screen and (max-width:600px) {
    .test-main {
        flex-direction: column;
        margin-bottom: 10px;
        margin-top: 60px ;
    }

    .test-h1 {
        font-size: 16px;
        line-height: 14px;
        font-weight: 500;
    }

    .back_in{
        margin: 0 !important;
    }

    .test-h2 {
        font-size: 18px;
        line-height: 14px;
        margin: 15px 0;
    }

    .test-p {
        font-size: 12px !important;
        line-height: 15px;
        /* margin: auto; */
    }

    .test-1 {
        width: 100%;
        /* text-align: center; */
    }

    .test-2 {
        padding: 10px;
        height: auto;
        width: auto;
        margin-top: 0;
        
    }

    .test-3 {
        display: none;
    }

    .test-2p {
        font-size: 12px !important;
    }

    .test-cards {
        margin-top: 20px;
    }

    .ceo-name {
        font-size: 12px !important;
        line-height: 10px;
    }

    .CEO {
        font-size: 10px !important;
    }

    .test-cards {
        width: 100%;
    }

    .test-slid-btns{
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    .left1{
        height: 35px;
    }

    .right1{
        height: 35px;
    }


}