.LakeComm-Whyu-Wrapper .LakeComm-Whyu-HeadingWrappper h4 {
  margin-bottom: 0;
}

.LakeComm-Whyu-Wrapper .LakeComm-Whyu-HeadingWrappper p {
  max-width: 800px;
  margin: auto;
}

.LakeComm-Whyu-Content .LakeComm-Whyu-ContentImage {
  border-radius: 50%;
  border: 2px solid #bfebfa;
  max-width: 70px;
  max-height: 70px;
  height: 100%;
  width: 100%;
  box-shadow: 10px 10px 10px #bfebfa;
}

.LakeComm-Whyu-Content .LakeComm-Whyu-ContentReason span {
  font-size: 14px;
  color: gray;
  padding-top: 5px;
  padding-bottom: 15px;
}

.LakeComm-Whyu-Content .LakeComm-Whyu-ContentReason h6 {
  font-size: 18px;
  color: rgb(0, 0, 0), 0.1;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 15px;
}

.LakeComm-Whyu-Content .LakeComm-Whyu-colum {
  border-radius: 45px;
  /* box-shadow: 0px 3px 6px 1px #bfebfa; */
  box-shadow: 0px 3px 6px 1px #53afdd;
  margin-top: 10px;
  transition: 0.3s ease-in-out;
}

.LakeComm-Whyu-Content .LakeComm-Whyu-colum:hover {
  background-color: rgb(191, 235, 250, 0.3);
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.justify-content-center .LakeComm-Whyu-colum .LakeComm-Whyu-Contentpara ul {
  list-style: none;
  padding: 0;
}

/* background css */
.LakeHome-Whyus-background1 {
  right: 0%;
  top: -2%;
  height: 250px;
  width: 250px;
  /* background: rgb(211, 211, 211, 0.2); */
}
.LakeHome-Whyus-background2 {
  right: -20%;
  top: 80%;
  height: 200px;
  width: 300px;
  background: rgb(211, 211, 211, 0.2);
}
.LakeHome-Whyus-background1,
.LakeHome-Whyus-background2,
.LakeHome-Whyus-background3 {
  border-radius: 50%;
}
.LakeHome-Whyus-background3 {
  left: 10%;
  top: 70%;
  height: 300px;
  width: 300px;
  /* background: rgb(211, 211, 211, 0.2); */
}

.LakeComm-Whyu-Contentpara li {
  height: 96px;
}
.LakeComm-Whyu-Wrapper .LakeComm-Whyu-HeadingWrappper h2 {
  color: #005082;
}

@media screen and (max-width: 480px) {
  .LakeHome-Whyus-background2 {
    display: none;
  }
  .LakeHome-Whyus-background3 {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .LakeComm-Whyu-Contentpara li {
    height: auto !important;
  }
  .row{
    height: fit-content;
  }
}
@media screen and (max-width: 991px) {
  .LakeComm-Whyu-Contentpara li {
    height: 160px;
  }
}
@media screen and (max-width: 1110px) {
  .LakeHome-Whyus-background3 {
    left: 0%;
  }
}
