/* hire page section 1 start */
.Body-div h2 {
  font-size: 38px;
  font-weight: 800;
  line-height: 1.5;
}

.Body-div h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}

.Body-div p {
  font-size: 16px;
  line-height: 1.5;
}

/* .Body-div .hire-banner-main {
  background-color: #ffffff;
  font-family: "Lato", sans-serif;
} */

.hire-home-btn:hover {
  text-decoration: none;
}
.banner-subtitle,
.contactform-subtitle {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.banner-title,
.contactform-title {
  font-weight: 700;
  line-height: 56px;
  font-size: 40px;
  color: #005082;
}

.banner-text {
  color: black;
  font-size: 16px;
  line-height: 30px;
}
.hire-image-rap {
  /* height: 100%; */
  width: 80%;
  margin: auto;
}
.hire-image {
  width: 100%;
  border-radius: 72% 70% 60% 100% / 89% 78% 69% 57%;
}

.banner .banner-request-btn {
  color: #ffffff;
  font-weight: 700;
  border-radius: 25px 5px 25px 5px;
  letter-spacing: 1px;
}

.arrow-image {
  width: 20px;
}

/* hire page section 1 end */

/* hire page section 2 end */

.contactform-fluid {
  background-color: #bfebfa;
}
.contactform-text {
  font-size: 20px;
  font-weight: 700;
}
.contactform-des {
  font-size: 16px;
  color: black;
}
.right-tick {
  float: left;
}
.icon {
  color: #ff8a46;
}

/* Contact form start */

#name,
#number,
#email,
#message {
  border-radius: 25px;
}

.form-hire .form-sub-btn {
  border-radius: 15px;
  border-radius: 25px 5px 25px 5px;
  color: #e6eaf5;
  font-size: 15px;
  font-weight: 700;
}

.start-icon {
  color: red;
}
/* Contact form start */

/* Your Workforce css start  */

.card-form-subtitle {
  font-size: 15px;
  color: #5381bf;
  font-weight: 600;
}

.card-main-title {
  font-weight: 700;
  color: #1a294e;
  font-size: 38px;
}
.Workforce {
  transition: 0.3s all ease-in-out;
}
.Workforce:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.Workforce-title {
  font-size: 18px;
  color: rgb(0, 0, 0), 0.1;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 15px;
}

.Workforce-description {
  /* color: #1d4e86; */
  /* font-weight: 400; */
  font-size: 16px;
  height: 96px;
}

.Workforce-btn-card {
  border: none;
  background-color: #fefefe;
}

.Workforce-btn-card:hover {
  background-color: #0c6ca8;
  color: #fefefe;
  border-radius: 25px;
}

/* Why Choose Us page css start */
.chooseus-subtitle {
  font-weight: 700;
  color: #1d4e86;
}

.chooseus-title {
  font-weight: 700;
  font-size: 40px;
}
.choose-us {
  background-color: #005082;
}

.icon-tick {
  float: left;
}
.icon-des h4 {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 2px;
}

.icon-des p {
  font-size: 16px;
  color: #c7ceda;
}

/* faq's page css start */

.faq-div h4 {
  text-align: left;
  text-transform: uppercase;
}
.faq-div h2 {
  color: #005082;
  margin-bottom: 10px !important;
  text-align: left;
  text-transform: capitalize;
}
.faqs-subtitle {
  font-size: 15px;
  font-weight: 600;
  color: #2c567e;
}

.faqs-title {
  color: #1a2942;
  font-weight: 600;
  font-size: 40px;
}

.faqs .faqs-btn {
  background-color: #005082;
  font-size: 13px;
  font-weight: 600;
  border-radius: 25px 5px 25px 5px;
}

/* Leader css start */
.leader-des {
  position: relative;
}

.leader-img {
  height: auto;
  float: right;
}

.leader-all {
  position: absolute;
  top: -100px;
  left: 100;
  background-color: #005082;
  width: 450px;
}
.leader-subtitle {
  font-size: 16px;
}

.leader-title {
  font-size: 32px;
  font-weight: 700;
  word-spacing: 3px;
  letter-spacing: 3px;
}

.business {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  min-height: 365px;
}
.business:hover {
  /* background-color: rgb(191, 235, 250, 0.3); */
  /* background-color: #0c6ca8; */
  /* opacity: 0.1; */
  transition: 0.3s all ease-in-out;
}

.business-row {
  margin-top: -40px;
}
.business h4 {
  font-size: 20px;
  font-weight: 700;
  /* color: #e67410; */
  color: #005082;
}

.business p {
  font-size: 18px;
  font-weight: 500;
  /* color: #0f0f0e; */
}

/* Why page start css */
.why-d-wrapper {
  background-color: #f2f2f2;
}
.why-d-page h1 {
  font-size: 40px;
  font-weight: 800;
  word-spacing: 10px;
  color: #0f0f0e;
}

.why-d-page {
  font-size: 22px;
  font-weight: 100;
}
.why-img-1 {
  float: left;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.why-hover1,
.why-hover2,
.why-hover3,
.why-hover4,
.why-hover4,
.why-hover5,
.why-hover6,
.why-hover7,
.why-hover8 {
  background-color: #fff;
  border-radius: 1px;
  outline: 0;
  box-shadow: 0px 0px 12px 2px rgb(229, 229, 229);
  border-radius: 8px;
  padding: 8px;
}

.why-heading {
  font-size: 23px;
  font-weight: 700;
}

.why-para {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.why-hover1:hover {
  background-color: #fdf2e8;
  cursor: pointer;
}

.why-hover1 h4 {
  color: #fd7b2d;
  cursor: pointer;
}

.why-hover2 h4 {
  cursor: pointer;
  color: #417cfb;
}

.why-hover2:hover {
  background-color: #f0f7ff;
  cursor: pointer;
}
.why-hover3 h4 {
  color: #5dcfba;
  cursor: pointer;
}
.why-hover3:hover {
  background-color: #ebfef8;
  cursor: pointer;
}

.why-hover4:hover {
  background-color: #fdf2e8;
  cursor: pointer;
}

.why-hover4 h4 {
  color: #fd7b33;
}

.why-hover5 h4 {
  color: #417cfb;
}

.why-hover5:hover {
  background-color: #f0f7ff;
  cursor: pointer;
}

.why-hover6 h4 {
  color: #5dcfba;
}
.why-hover6:hover {
  background-color: #ebfef8;
  cursor: pointer;
}
.why-hover7:hover {
  background-color: #fdf2e8;
  cursor: pointer;
}

.why-hover7 h4 {
  color: #fd7b33;
}

/* .why-hover7 h4:hover {
  background-color: #417cfb;
  cursor: pointer;
} */

.why-hover8 h4 {
  color: #417cfb;
}

.why-hover8:hover {
  background-color: #f0f7ff;
  cursor: pointer;
}

.why-developer-image {
  max-height: 700px;
}

.why-developer-image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Workforce-items-01 {
  margin-top: 10px;
  transition: 0.3s ease-in-out;
}
.Workforce-items-01-00 {
  /* margin: 0px 0px; */
  border-radius: 6px;
  box-shadow: 4px -4px 8px 1px #c8e1ea;
  padding: 15px 20px;
}
.Workforce-items-wrapper {
  width: 40px;
  margin: auto;
}

.workforce-items-title {
  color: #5ac7f4;
  font-weight: 600;
  font-size: 12px !important;
  /* margin-top: 5px; */
}
.workforce-items-description {
  font-size: 12px !important;
  /* color: grey; */
}
.Workforce-items-wrapper img {
  /* border-radius: 50%; */
  /* border: 2px solid #bfebfa; */
  /* max-width: 70px; */
  /* max-height: 70px; */
  /* height: 100%; */
  width: 100%;
  /* box-shadow: 10px 10px 10px #bfebfa; */
}
.Workforce-items-01-00:hover {
  background-color: rgb(191, 235, 250, 0.3);
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
.let-choose-btn {
  background: #00508250;
  border: 1px solid #005082;
  color: #fff;
  border-radius: 50px;
  margin: 5px 5px;
  text-decoration: none;
  padding: 2px 20px !important;
  font-size: 16px;
  transition: 0.3s all;
  max-width: max-content;
  transition: 0.3s all ease-in-out;
}
.let-choose-btn:hover {
  background: #fff !important;
  border: 1px solid #005082 !important;
  color: #005082 !important;
}
.let-choose-btn-wrapper:hover a {
  /* color: white !important; */
  text-decoration: none;
  /* transition: 0.3s all ease-in-out; */
}
.let-choose-btn-wrapper {
  transition: 0.3s all ease-in-out;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .banner {
    margin-top: 0px;
  }
  .banner-subtitle {
    font-size: 15px;
    padding-top: 0;
  }
  .banner-title {
    line-height: 32px;
    font-size: 28px;
  }
  .banner-text {
    line-height: 25px;
    font-size: 15px;
  }
  .hire-image {
    width: 100%;
  }
  .contactform-row {
    margin-top: -10px;
  }
  .contactform-title {
    font-size: 28px;
    line-height: 32px;
  }
  .contactform-text {
    font-size: 18px;
  }

  .card-deck .card-title {
    font-size: 23px;
    padding: 0 !important;
  }
  .card-deck .card-text {
    font-size: 17px;
  }

  .faqs-title {
    font-size: 25px;
    font-weight: 700;
  }
  .leader-subtitle {
    font-size: 16px;
  }
  .leader-title {
    font-size: 30px;
  }
  .leader-all {
    max-width: 40%;
    height: auto;
  }
  .business {
    margin-top: 16px !important;
  }
  .why-img-1 {
    width: 40px;
  }
  .why-heading {
    font-size: 18px;
  }

  .why-para {
    font-size: 13px;
    line-height: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hire-banner-container .row {
    margin-top: 25px !important;
  }
  .banner-text {
    font-size: 15px;
  }
  .hire-image {
    margin-top: 50px !important;
  }
  .leader-row {
    align-items: center;
    justify-content: center;
  }
  .leader-all {
    position: relative;
    max-width: 100%;
    height: auto;
  }

  .leader-des {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .leader-all {
    top: 0;
    left: 50;
  }

  .leader-subtitle {
    font-size: 16px;
  }
  .leader-title {
    font-size: 28px;
  }
  .leader-all {
    max-width: 100%;
    max-height: auto;
  }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
  .hire-banner-container .row {
    display: flex;
    flex-direction: row !important;
  }
  .business {
    margin-top: 20px !important;
  }
  .business-row {
    margin-top: 0;
  }
  .leader-des .leader-all {
    width: 100%;
    max-height: auto;
    padding-bottom: 0 !important;
  }
  .leader-img {
    width: 100%;
  }
  .leader-subtitle {
    font-size: 16px;
  }
  .leader-title {
    font-size: 30px;
  }
  .why-img-1 {
    width: 30px;
  }

  .why-heading {
    font-size: 13px;
    margin-bottom: 3px !important;
    line-height: 16px;
  }

  .why-para {
    font-size: 12px !important;
    line-height: 16px;
    padding-top: 1 !important;
  }

  /* .why-developer-image-col{
        display: none;
        visibility: hidden;
    }*/
}

@media screen and (max-width: 700px) {
  .hire-banner-container .row {
    display: flex;
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: 425px) {
  .hire-image-rap {
    padding-bottom: 0 !important;
  }

  .hire-image {
    margin-bottom: 0 !important;
  }
  .leader-all {
    max-width: 100%;
    height: 90%;
  }
  .leader-subtitle {
    font-size: 16px;
  }
  .leader-title {
    font-size: 28px;
  }
  .leader-img {
    max-width: 100%;
    width: auto;
  }
  .business {
    margin-top: 20px !important;
  }
  .business-row {
    margin-top: 0;
  }
  .leader-des .leader-all {
    padding-bottom: 0 !important;
  }

  .leader-title {
    font-size: 25px;
  }

  .leader-subtitle {
    font-size: 16px;
  }
  /* why page css start  */
  .why-allhover-page1 .row {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .why-allhover-page2 .row {
    text-align: center;
  }
}

/* @media screen and (max-width: 576px) {
   .middle_image_hiredeveloper{
    display: none;
   }
  } */
