* {
    margin: 0;
    padding: 0;
}

.transcript-feature-container{
    margin-top: 143px;
}
.transcript-feature-heading {
    color: #00A8CD;
    /* font-family: Montserrat; */
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 79.5px;
}
.feature-option {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 79.5px;
}
.feature-content{
    gap: 14px;
}
.transcript-feature-img {
    position: relative;
}
.feature-img3 {
    position: absolute;
    /* width: 273px; */
}
.feature-img2 {
    position: absolute;
    /* width: 88.26px; */
    top: 43%;
    right: 20%;
}
.feature-img1 {
    position: absolute;
    /* width: 222px; */
    z-index: 5;
    top: 20%;
    left: 15%;
}
.feature-img5 {
    position: absolute;
    /* width: 107.62px; */
    bottom: 10%;
    right: 25%;
}
.feature-img6 {
    position: absolute;
    /* width: 163.79px; */
    z-index: 1;
    top: 25%;
    left: 19%;
}
.feature-img4 {
    position: relative;
    /* width: 524px; */
}
.feature-img7 {
    max-width: 20px;
}
.transcript-feature-radio{
    padding-left: 3rem;
}

@media screen and (max-width: 1200px){

    .transcript-feature-container{
        margin-top: 10vw;
    }
    .transcript-feature-heading {
        color: #00A8CD;
        font-size: 6vw;
        font-style: normal;
        font-weight: 600;
        line-height: 8vw;
    }
    .feature-option {
        color: #000;
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: 7vw;
    }
    .feature-content{
        gap: 1vw;
    }
    .feature-img3 {
        position: absolute;
        width: 20vw;
    }
    .feature-img2 {
        position: absolute;
        width: 8vw;
        top: 17vw;
        left: 30vw;
    }
    .feature-img1 {
        position: absolute;
        width: 18.5vw;
        z-index: 5;
        top: 20%;
        left: 15%;
    }
    .feature-img5 {
        position: absolute;
        width: 10vw;
        bottom: 3vw;
        left: 25vw;
    }
    .feature-img6 {
        position: absolute;
        width: 16.5vw;
        z-index: 1;
        top: 25%;
        left: 19%;
    }
    .feature-img4 {
        position: relative;
        width: 40vw;
    }
    .transcript-feature-radio{
        padding-left: 1rem;
    }
    .transcript-feature-radio{
        padding-left: 0;
    }
   
}

@media screen and (max-width: 576px){
    .feature-img7{
        width: 5vw;
    }
}