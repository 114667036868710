.os-main{
    margin-bottom: 80px;
    margin: auto;
}

.os-texts{
    text-align: center;
    width: 80%;
    margin: auto;
}

/* .os-main{
    margin-top: 20px;
} */

.boxes-3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-box{
    background-color: white;
    width: 40%;
     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
}
.os-R1{
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 0 40px;
    margin-top: 60px;
}
.os-R2{
    display: flex;
    justify-content: center;
    gap: 40px;
    margin:  0 40px;
    margin-top: 40px;
}

.os-h1{
    font-family: 'Lato',sans-serif;
    font-size: 30px;
    margin-bottom: 20px !important;
    color: rgba(0,0,0,1);
    font-weight: 600;
    line-height: 36px;
    /* width: 80%; */
    margin: auto;
    letter-spacing: 0em;
    text-align: center;

}

.os-h2{
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato',sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    /* letter-spacing: 0.05em; */
    text-align: center;   
    margin-bottom: 20px;
    /* padding: 40px; */
}

.os-p{
    font-family: 'Lato',sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0.05em;
text-align: center;
/* padding: 40px; */
width: 80%;
color: rgba(51, 51, 51, 1);

}

.R2{
    /* display: flex; */
    /* align-items: center; */
    margin: 10px;
}

.card-5 {
    margin-left: 160px;
}

.card-t1{
    font-family: 'Lato',sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
color: rgba(9, 81, 130, 1);
padding: 10px 0;
}



.card-tt2{
    font-family: 'Lato',sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.01em;
text-align: left;
color: rgba(111, 108, 108, 1);
}


.card-title1{
    color: rgba(9, 81, 130, 1);
}

.R1{
    padding-top: 30px;
}

.readMore{
    position: relative;
    bottom: 0;
}
.Services{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Services:hover{
    background-color: #03A6CA;
}
.readMore-a{
    border-bottom: 1px solid #03A6CA;
}
.ServicesImg{
    border-radius: 100%;
}
.Services:hover .Services-h,
.Services:hover .readMore-a{
    color: black !important;
}
.Services:hover .readMore-a{
    border-bottom: 1px solid black;
}

@media screen and (max-width:992px) {
    .os-h1{
        font-size: 18px;
        margin-bottom: 0px;
        line-height: 23px;

    }

    .os-texts{
        width: 100%;
    }
    .os-h2{
        font-size: 26px;
        margin: 0;
        line-height: 32px;
        margin-bottom: 15px;
    }
    .os-p{
        font-size: 17px;
    }

    .os-R1{
        gap: 30px;
        margin: 0;
        margin-top: 20px;
    }
    .os-R2{
        gap: 30px;
        margin: 0;
        margin-top: 20px;
    }
    .readMore-a{
        font-size: 12px;
    }
}

@media screen and (max-width:600px) {
    .os-h1{
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
    }

    .os-h2{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .os-p{
        font-size: 12px;
        line-height: 15px;
    }

    .os-texts{
        line-height: 10px;
        width: 90%;
    }


    .os-b{
        justify-content: center;
        align-items: center;
    }

    .os-R1{
        gap: 20px;
        margin: 0;
        margin-bottom: 20px;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
    }
    .os-R2{
        /* margin-top: 20px; */
        gap: 20px;
        margin: 0;
        padding: 0 20px;
        margin-bottom: 20px;
        flex-direction: column;
        width: 100%;
    }

    .w-box{
        width: 100%;
        align-items: center;
    }

    .readMore-a{
        font-size: 10px;
    }
}