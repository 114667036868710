body p {
  font-size: 16px;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h6,
p,
ul li {
  font-family: "Lato";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}
body h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}
#hubspotForm {
  max-height: 440px;
  overflow-y: hidden;
}
body h2 {
  /* font-size: 38px; */
  font-weight: 800;
  line-height: 1.5;
}

#root {
  background-image: url(../images/bg/bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.example-classname {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  font-size: 18px;
  font-weight: 400 !important;
}
.navbar-nav .nav-link {
  border-bottom: 2px solid transparent;
}

/* calendly */
.Es8downQlxiASDyltHtn::-webkit-scrollbar {
  display: none;
}
.Es8downQlxiASDyltHtn {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.homepage-heading {
  margin-bottom: 25px;
  margin-top: 6rem;
}
.homepage-heading h2 {
  color: #005082;
  margin-bottom: 10px !important;
  text-align: center;
  text-transform: capitalize;
}
.homepage-heading h4 {
  text-align: center;
  text-transform: uppercase;
}
.homepage-heading p {
  max-width: 50%;
  margin: auto;
  text-align: center;
}
.control-prev.control-arrow:before {
  border-right: 20px solid #000000 !important;
}
.Lake-Case-Carousel-Container .control-prev.control-arrow:before {
  border-right: 20px solid #00000050 !important;
}
.Lake-Case-Carousel-Container .carousel .control-next.control-arrow:before {
  border-left: 20px solid #00000050 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 20px solid #000000 !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-bottom: 20px solid transparent !important;
  border-top: 20px solid transparent !important;
  content: "";
  display: inline-block;
  margin: 0 5px;
}
.active {
  color: #005082 !important;
  border-bottom: 2px solid #0c6ca8;
}
@media screen and (max-width: 768px) {
  p {
    font-size: 16px !important;
  }
  h2 {
    /* font-size: 32px !important; */
  }
  .homepage-heading p {
    max-width: 85%;
  }
}

@media screen and (max-width: 425px) {
  .example-classname {
    padding: 50px 0px;
  }
}
