.hirebest-image-wrapper {
  width: 40px;
}
.hirebest-image-wrapper img {
  width: 100%;
}

.hirebest-inquire-btn {
  color: white;
  background-color: black;
  font-weight: 600;
  padding: 7px 18px;
  border-radius: 18px;
}
.hirebest-item-title {
  background-color: #5ac7f4;
  font-weight: 600;
  padding: 7px 18px;
  border-radius: 6px;
}

.hirebest-item-index {
  background-color: #005082;
  color: white;
  font-weight: 600;
  /* padding: 7px; */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 50%;
}
.hirebest-items-wrapper-00 {
  position: relative;
}

.hirebest-image-wrapper-01 {
  border: 7px solid #005082;
  border-radius: 50%;
  width: 104px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.hirebest-image-wrapper-02 {
  border: 5px solid #5ac7f4;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hirebest-child-wrapper::nth-child(1)::after {
  position: absolute;
  top: 44px;
  /* right: 28px; */
  left: 0;
  content: "";
  background: url("../../../../images/HireDeveloper/arrow.png");
  width: 160px;
  height: 15px;
  color: black;
  z-index: 99999;
  background-size: 100%;
  transform: translateX(-50%);
}
.hirebest-child-wrapper:nth-child(3)::after {
  position: absolute;
  top: 44px;
  left: 0;
  content: "";
  background: url("../../../../images/HireDeveloper/arrow.png");
  width: 160px;
  height: 15px;
  color: black;
  z-index: 99999;
  background-size: 100%;
  transform: translateX(-50%);
}
.hirebest-child-wrapper:nth-child(2)::after {
  position: absolute;
  top: 44px;
  left: 0;
  content: "";
  background: url("../../../../images/HireDeveloper/arrow.png");
  width: 160px;
  height: 15px;
  color: black;
  z-index: 99999;
  background-size: 100%;
  transform: translateX(-50%);
}
.hirebest-child-wrapper:nth-child(4)::after {
  position: absolute;
  top: 44px;
  left: 0;
  content: "";
  background: url("../../../../images/HireDeveloper/arrow.png");
  width: 160px;
  height: 15px;
  color: black;
  z-index: 99999;
  background-size: 100%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1180px) {
  .hirebest-child-wrapper:not(:nth-child(1))::after {
    position: absolute;
    top: 44px;
    left: 0;
    content: "";
    background: url("../../../../images/HireDeveloper/arrow.png");
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 1023px) {
  .hirebest-child-wrapper:nth-child(2)::after {
    position: absolute;
    top: 44px;
    left: 0;
    content: "";
    background: url("../../../../images/HireDeveloper/arrow.png");
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: translateX(-50%);
  }
  .hirebest-child-wrapper:nth-child(4)::after {
    position: relative;
    top: 44px;
    left: 0;
    content: "";
    /* background: url("../../../../images/HireDeveloper/arrow.png"); */
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 766px) {
  .hirebest-child-wrapper:nth-child(3)::after {
    position: relative;
    top: 44px;
    left: 0;
    content: "";
    /* background: url("../../../../images/HireDeveloper/arrow.png"); */
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: translateX(-50%);
  }
  .hirebest-child-wrapper:nth-child(4)::after {
    position: absolute;
    top: 44px;
    /* right: 28px; */
    left: 0;
    content: "";
    background: url("../../../../images/HireDeveloper/arrow.png");
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 572px) {
  /* .hirebest-child-wrapper {
    padding-bottom: 99px;
  } */
  .hirebest-child-wrapper:nth-child(odd)::after {
    position: relative;
    bottom: 0;
    content: "";
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    /* background: url("../../../../images/HireDeveloper/arrow.png"); */
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: translateX(-50%);
    transform: rotate(90deg);
  }
  .hirebest-child-wrapper:nth-child(even)::after {
    position: relative;
    bottom: 30px;
    content: "";
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    /* background: url("../../../../images/HireDeveloper/arrow.png"); */
    width: 120px;
    height: 12px;
    color: black;
    z-index: 99999;
    background-size: 100%;
    transform: rotate(90deg);
  }
}
