.what-main {
    /* margin-bottom: 40px; */
    margin: 130px auto 130px 0;
}


.what-texts {
    text-align: center;
    /* width: 70%; */
    margin: auto;
}

/* .what-main{
    margin-top: 20px;
} */

.boxes-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-box {
    background-color: white;
    width: 50% !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* padding: 40px !important; */
}

.what-R1 {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 60px 40px 0 40px;
    /* width: 90%; */
}

.what-R2 {
    display: flex;
    justify-content: center;
    gap: 40px;
    /* width: 90%; */
    margin: 40px;
}

.what-h1 {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;

}

.what-h2 {
    color: rgba(9, 81, 130, 1);
    font-family: 'Lato', sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    /* letter-spacing: 0.05em; */
    text-align: center;
    margin-bottom: 20px;
    /* padding: 40px; */
}

.what-p {
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 400;
    width: 80%;
    margin: auto;
    line-height: 22px;
    /* letter-spacing: 0.05em; */
    text-align: center;
    /* padding: 40px; */
    /* width: 80%; */
    color: rgba(51, 51, 51, 1);

}

.R2 {
    /* display: flex; */
    /* align-items: center; */
    margin: 10px;
}

.card-5 {
    margin-left: 160px;
}

.card-t1 {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(9, 81, 130, 1);
    padding: 10px 0;
}



.card-tt2 {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(111, 108, 108, 1);
}


.card-title1 {
    color: rgba(9, 81, 130, 1);
}

.R1 {
    padding-top: 30px;
}

.w-box:hover {
    background-color: #03A6CA;
}

.w-box:hover .box-h,
.w-box:hover .box-p {
    color: white !important;
}

.w-boxImg {
    width: 60px;
    background: #BFE5EF;
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
    border-radius: 100%;
}

.w-box:hover .w-boxImg {
    background: white;
}

.AIimg{
    border-radius: 100% !important;
}


@media screen and (max-width:992px) {
    .what-h1 {
        font-size: 18px;
        margin-bottom: 0;

    }

    .what-h2 {
        font-size: 26px;
        margin-bottom: 0;
    }

    .what-R1 {
        gap: 30px;
        margin: 25px;
    }

    .what-R2 {
        gap: 30px;
        margin: 25px;
    }

    .what-main{
        margin: 20px auto 130px 0;
    }

}

@media screen and (max-width:600px) {

    .what-h1 {
        font-size: 15px;
        line-height: 12px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .what-h2 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .what-p {
        font-size: 12px !important;
        line-height: 15px;
        width: 100%;
    }

    .what-R1 {
        gap: 20px;
        margin: 0;
        margin-bottom: 20px;
    }

    .what-R2 {
        /* margin-top: 20px; */
        gap: 20px;
        margin: 0;
    }

    .what-texts {
        line-height: 10px;
        width: 90%;
    }

    .w-box {
        padding: 5px !important;
    }

    .boxes-3 {
        margin-top: 20px;
    }

    .what-main{
        margin-bottom: 60px;
        margin-top: 60px;
    }

}

@media screen and (max-width:576px) {

    .what-R1,
    .what-R2 {
        flex-direction: column;
    }

    .w-box {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        align-items: center;
    }

    .w-box .box-h,
    .w-box .box-p {
        text-align: center;
    }
}