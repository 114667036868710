.services-section {
  text-align: center;
  margin: auto;
  /* padding: 80px 0 30px; */
}

.services-section .headings {
  margin: 0 0 30px;
}

.react-tabs__tab {
  padding: 6px 25px;
  margin: 0px 16px;
}

.react-tabs__tab {
  border: 2px solid #005082;
  color: #005082;
  border-radius: 15px;
  font-weight: 600;
}

.react-tabs__tab--selected {
  border: 2px solid #005082;
  color: #fff;
  background: #005082;
  box-shadow: 1px 1px 10px 1px #00508220;
  z-index: 99;
}

.react-tabs__tab-list {
  border: none;
}

.services-section h1 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.services-section h2 {
  font-weight: 600;
  color: #005082;
  margin-bottom: 24px !important;
}

a {
  text-decoration: none !important;
}

.tabsForServices .tab-cntnt {
  padding-top: 20px;
}

.tabsForServices .sideImageWrap {
  border-radius: 50%;
  border: 5px solid #f0f0f0;
  width: 80%;
  margin: auto;
}

.tabsForServices .sideImageWrap img,
.tabsForServices .sideImageWrap video {
  border-radius: 50%;
}

.tabsForServices .sideImageWrap video {
  max-width: 100%;
}

.tabsForServices .tab-cntnt h4 {
  font-size: 18px;
  color: #005082;
  margin-bottom: 20px;
}

.tabsForServices .tab-cntnt .technologies img {
  width: 75px;
  /* width: 100px; */
  margin: 0 auto 16px;
  border-radius: 50px;
  box-shadow: 4px 4px 10px 4px #bfebfa50;
  padding: 8px;
  border: 1px solid #bfebfa;
  transition: all 0.3s;
}

.tabsForServices .tab-cntnt .technologies .singleTech:hover img {
  box-shadow: 4px 4px 10px 4px #00508220;
  border: 1px solid #00508220;
}

.tabsForServices .tab-cntnt .technologies .singleTech {
  transition: all 0.3s;
  border-radius: 15px;
  margin: 8px 0 0 0;
  border: 1px solid transparent;
}

.tabsForServices .tab-cntnt .technologies .singleTech:hover {
  box-shadow: 1px 1px 10px 1px #00508220;
  border: 1px solid #00508220;
  background-color: rgb(191, 235, 250, 0.3);
  cursor: pointer;
}

.tabsForServices .tab-cntnt .technologies p {
  font-weight: 600;
  font-size: 16px;
}

.tabsForServices .tab-cntnt .technologies .singleTechWrap .singleTech {
  padding: 8px;
}

.tabsForServices .react-tabs__tab {
  min-width: 280px;
}

.caseStudyWrap .carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.caseStudyWrap .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.caseStudyWrap .head {
  text-align: left;
  color: #005082;
  margin-left: 30px;
  font-weight: 600;
  width: max-content;
}

.caseStudyWrap .inner-cntnt .link {
  width: max-content;
  float: right;
}

.caseStudyWrap .casestudy-slider {
  margin: 30px auto 20px;
}

.slideWrap {
  padding: 20px;
}

.caseStudyWrap .tabSlide {
  box-shadow: 1px 1px 8px 1px #00000020;
  border-radius: 16px;
  z-index: 1;
  max-width: 90%;
  margin: auto;
  padding: 16px;
}

.service-bg-row {
  padding: 15px 0px;
  box-shadow: 1px 1px 10px 1px #00000020;
  border-radius: 25px;
  background: #bfebfa50;
}

.tabsForServices .react-tabs__tab:hover {
  background: #005082;
  color: white;
  box-shadow: 1px 2px 3px 0px #005082;
}

.ServiceSection{
  display: flex;
  align-items: center;
  justify-content: center;
}
.technologies div{
  /* gap: 20px; */
}

@media only screen and (max-width: 1199px) {
  .tabsForServices .react-tabs__tab {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .tabsForServices .tab-cntnt .technologies img {
    /* width: 50px; */
    width: 50px;
  }

  .tabsForServices .tab-cntnt .technologies p {
    font-size: 14px;
  }

  .casestudy-slider .slideWrap img {
    margin-bottom: 20px;
  }

  .tabsForServices .sideImageWrap {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .tabsForServices .tab-cntnt .technologies .singleTechWrap .singleTech {
    margin: 24px auto 0;
    max-width: max-content;
    padding: 16px;
  }

  .services-section {
    margin-top: 250px;
  }

  .technologies div{
    gap: 0px;
  }
}

@media only screen and (max-width: 990px) {
  .services-section {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 425px) {
  .example-classname{
    padding: 0 !important;
  }
}