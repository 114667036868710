* {
    margin: 0;
    padding: 0;
}

.OurBenefits-heading {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.OurBenefit-image {
    /* width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: none;
    padding: 0;
    border: none;
    background: none;
    transition: all 0.3s; */
}

.OurBenefits-text {
    max-width: 1156px;
    margin: auto;
}

.OurBenefits-text h4 {
    /* color: #333 !important; */
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
}

.OurBenefits-text h2 {
    color: var(--high, #095182);
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.OBcontent{
    display: flex;
   justify-content: space-around; 
   margin-top: 80px;
}

.OurBenefits-container {
    margin-top: 25px;
    /* row-gap: 50px; */
}

.OBimg{

border-radius: 60px 0;
box-shadow: 5px 12px 0 0 rgba(9, 81, 130, 1);

}

.OurBenefits-teams {
    gap: 25px;
    padding: 0px 20px 20px 20px !important;

    width: fit-content;
    /* margin: auto; */
    /* border-radius: 50px 12px;
    border: 1px solid var(--high, #095182);
    background: #FFF;
    box-shadow: 0px 4px 0px 0px #095182; */
}

.archive-teams-data {
    /* padding:0 65px; */
}

.OurBenefits-teams h3 {
    /* max-width: 160px    ; */

    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media screen and (max-width: 992px) {
    .OurBenefits-container {
        justify-content: center !important;
    }

    .OurBenefits-text h2 {
        font-size: 36px;
    }

    .OurBenefits-teams {
        gap: 20px;
    }
}

@media screen and (max-width: 576px) {
    .OurBenefits-teams {
        gap: 15px;
        margin-top: 30px;
    }

    .OurBenefits-teams h3 {
        font-size: 16px;
    }

    .OurBenefits-teams img {
        height: auto;
    }

    .OBcontent{
        flex-direction: column;
    }

   .OBimg{
    display: flex;
    width: 70%;
    margin: auto;
   }

   .OurBenefits-container{
    margin-top: 30px !important;
    margin: auto;
   }

}