

.video-background {
    /* margin-top: 55px; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.elementor-element-populated{
    z-index: 2;
    background-color: #FFFFFF99;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 25px;
    width: 890px;
    /* height: 28%; */
}
.elementor-element-blog{
    color: #000 !important;
    border-bottom: 2px solid #005082;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    line-height: 35px;
}
.elementor-element-mtext{
    color: #005082 !important;
    font-size: 46px;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
}
.elementor-element-ltext{
    color: #000 !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    font-family: "Lato", Helvetica, Arial, sans-serif;
}
.elementor-widget-container{
    color: #000;
    width: fit-content;
    width: -moz-fit-content;
    margin-top: 50px;
}
.cat{
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 15px;
}
.cat-item{
    color: #000839;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    list-style: none;
    display: inline-block;
    margin: 0 10px 20px 0 !important;
    text-align: center;
    padding: 13px 20px;
    border-radius: 20px;
    background: #bfebfa50;
}

.active-category-btn{
    color: #005082 !important;
    background: white !important;
    border: 2px solid #005082;
    box-shadow: 5px 5px 5px 0px #005082 !important;
    font-weight: 600;
    transition: all 0.3s;
}
.inactive-category-btn:hover {
    color: #005082 !important;
    background: white !important;
    border: 2px solid #005082;
    box-shadow: 5px 5px 5px 0px #005082;
    font-weight: 600;
    transition: all 0.3s;
}

.Card_Catagory_Section{
    row-gap: 20px;
}

@media(max-width: 992px){
    .elementor-element-populated{
        margin: 50px;
    }
}

@media(max-width: 768px){
    .video-background {
        display: none;
    }
    .elementor-section{
        margin-top: 500px;
        background: none;
        height: fit-content !important;
        height: -moz-fit-content !important;
        margin-top: 80px;
    }
    .elementor-element-populated{
        width: auto;
        margin: 0;
        height: auto;
        line-height: 15px;
    }

}
@media(max-width: 576px){
    .elementor-element-populated{
        margin: 20px;
        padding: 15px;
    }
    .elementor-element-blog{
        font-size: 18px;
    }
    .elementor-element-mtext{
        font-size:35px;
    }
    .elementor-element-ltext{
        font-size: 15px;
    }
}

