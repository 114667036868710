.form-main{
    /* background-color: white; */
    padding: 0 30px;
    width: 90% !important;
    padding: 50px;
    margin-top: 100px;
    margin-bottom: 40px;
}

.in{
    background-color: rgba(252, 252, 251, 1) !important;
    background-clip: initial !important;
    padding: 30px;
    font-size: 16px;
    border-radius: 0px !important;
    /* color: rgba(182, 182, 182, .1) ; */
    border: 0.1px solid rgba(182, 182, 182, .5) !important;
}

.in1{
    background-color: rgba(252, 252, 251, 1) !important;
    padding: 30px;
    font-size: 16px;
    border: none !important;
    border-radius: 0 !important;
}

.form-texts{
    text-align: center;
}


.form-heading{
    text-align: center;
    font-family: 'Lato',sans-serif;
font-size: 50px;
font-weight: 600;
line-height: 60px;
color: rgba(9, 81, 130,1);
padding: 20px;

}

.form-content{
    margin-top: 40px;
}


.form-t{
    font-family: 'Encode Sans',sans-serif;
font-size: 24px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
color: black;
text-align: center;
padding: 10px;
margin-bottom: 20px;

}

.f-box{
    border: 0.5px solid rgba(182, 182, 182, .1);
    background-color: rgba(182, 182, 182, .1);
    margin: 30px 0px;
}

.f-boxM{
    margin: 30px 0;
    border: none !important;
}

.f-btn{
    display: flex;
    /* align-self: stretch; */
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: center;
    color: white;
    border: 1px solid rgba(9, 81, 130, 1);
    font-family: 'Lato',sans-serif;
font-size: 23px;
font-weight: 400;
line-height: 28px;
/* letter-spacing: 0em; */
text-align: center;

    background-color: rgba(9, 81, 130, 1);
}

.f-btn:hover{
background-color: white;
color: rgba(9, 81, 130,1);
}

@media screen and (max-width:992px) {
    .form-heading{
        font-size: 26px;
    }

    .form-t{
        font-size: 20px;
    }

    .form-main{
        margin-top: 20px;
    }
}

@media screen and (max-width:600px) {
    .form-heading{
        font-size: 18px;
        padding: 0%;
    }

    .in{
        padding: 20px;
        font-size: 12px;
    }
     
    .in1{
        padding: 20px;
        font-size: 12px;
    }

    .form-main{
        width: 100% !important;
        padding: 10px;
        margin: 0;
    }
    .f-box{
        margin: 20px 0;
    }

    .f-boxM{
        margin: 20px 0;
    }
    .form-t{
        font-size: 12px;
        line-height: 12px;
    }

    .form-texts{
        line-height: 12px;
    }

    .f-btn{
        font-size: 18px;
    }
    
}