* {
    margin: 0;
    padding: 0;
}

.que-h {
    color: #00A8CD;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.96px;
}

.que-text {
    color: #333;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.44px;
    margin-bottom: 65px;

    max-width: 790px;
}

.f-head {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;

    display: flex;
    /* font-family: 'Lato',sans-serif; */
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 15px;
}

#queparaC {
    color: #1E1E1E;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;

    margin-left: 20px;
    margin-bottom: 20px;
}

.faqtitle {
    width: 100%;
}

.QueImg img {
    width: 21.6px;
    /* height: 12px; */
}

.queDivPart1 {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 117px;
}

.f-head button {
    background: none;
}


@media screen and (max-width:992px) {
    .que-h {
        font-size: 5vw;
    }

    .que-text {
        font-size: 2.3vw;
        margin-bottom: 4vw;
        width: 75vw;
    }

    .f-head {
        font-size: 2.5vw;
        margin: 3vw 0;
        gap: 1.5vw;
    }

    #queparaC {
        font-size: 2.1vw !important;
        margin-left: 2vw;
        margin-bottom: 2vw;
    }

    .faqtitle {
        width: 100%;
    }

    .QueImg img {
        width: 2.5vw;
    }

    .queDivPart1 {
        margin-top: 8vw;
    }

}