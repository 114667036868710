* {
    margin: 0;
    padding: 0;
}

/* .NextClick {
    top: 150px;
    left: 50px;
    color: #095182;
}

.PreClick {
    left: 0;
    top: 150px;
    color: #095182;
}

.CaseStudySlider-text {
    text-align: left;
    width: 500px;
}

.CaseStudySlider-text h4 {
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.79px;
    text-transform: uppercase;
}

.CaseStudySlider-text h2 {
    color: #095182 !important;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.CaseStudySlider-text p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;

    margin-top: 32px;
}

.CaseStudySlider-test {
    height: auto;
    flex-shrink: 0;
    border-radius: 30px 10px;
    border: 1px solid var(--med, #03A6CA);
    background: #FFF;
    box-shadow: 0px 4px 0px 0px #03A6CA;
    box-sizing: border-box;

    text-align: left;
    padding: 82px 36px 33px 59px;
}

.CaseStudySlider-testimonial-Person {
    width: 30%;
    height: 70px;
    flex-shrink: 0;
}

.CaseStudySlider-testimonial-text {
    color: #161313;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.testimonial-Person-text h1 {
    color: var(--high, #095182);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
}

.testimonial-Person-text span {
    color: #2F2A2A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
}

 */





.test-main {
    margin: 100px 0 !important;
}
.test-btn{
    margin-top: 45px !important;
}

.CaseStudySlider-test-2 {
    border-radius: 30px 10px;
    border: 1px solid var(--med, #03A6CA);
    background: #FFF;
    box-shadow: 0px 4px 0px 0px #03A6CA;
    width: 464px;
    height: 470px;
    padding: 60px 36px 45px 59px;
    position: relative;

    transition: all 0.5s ease !important;
}

.Testimonials-bg {
    position: absolute;
    top: 5%;
    left: 10px;
    z-index: 0;
}

.CaseStudySlider-test-2 span {
    font-size: 16px;
    position: relative;
    z-index: 99;
}

.CaseStudySlider-text h4 {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.79px;
    text-transform: uppercase;
}

.CaseStudySlider-text h2 {
    color: var(--high, #095182);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.CaseStudySlider-text P {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;

    margin-top: 15px;
    padding-right: 15%;
}

.CaseStudySlider-text {
    margin-left: 10%;
    padding-top: 10%;
}

.NewSlider-Btn {
    position: absolute;
    left: 10%;
    top: 55%;
    margin-top: 25px;
}
@media screen and (max-width: 1200px) {
    .test-slid-btns {
        margin-top: 50px !important;
    }
}

@media screen and (max-width: 992px) {
    .CaseStudySlider-text {
        text-align: center;
        margin-left: 0;
    }

    .CaseStudySlider-text h4 {
        /* font-size: 12px !important; */
    }

    .CaseStudySlider-text h2 {
        /* font-size: 22px !important; */
    }

    .CaseStudySlider-text p {
        /* font-size: 16px !important; */
        margin-top: 10px;
    }

    .CaseStudySlider-test-2 {
        border-radius: 30px 10px;
        border: 1px solid var(--med, #03A6CA);
        background: #FFF;
        box-shadow: 0px 4px 0px 0px #03A6CA;
        width: 100%;
        /* min-height: 105vw; */
        height: auto;
        padding: 20px 30px 0px;
        /* display: flex !important; */
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }

    .test-main {
        padding-bottom: 100px;
        gap: 85px;
    }

    .NewSlider-Btn {
        top: unset;
    }

    .CaseStudySlider-test-2 span {
        /* font-size: 3.5vw; */
    }

    .Testimonials-bg {
        width: 35px;
    }

    .CaseStudySlider-text p {
        /* font-size: 16px !important; */
        text-align: center;
        padding: 0;
        margin-top: 10px;
    }
    .test-btn{
        margin-top: 0;
        position: absolute;
        bottom: 3px;
        right: 0;
    }
}


@media screen and (max-width: 576px) {
    .CaseStudySlider-text {
        text-align: center;
        margin-left: 0;
    }

    .CaseStudySlider-text h4 {
        /* font-size: 12px !important; */
    }

    .CaseStudySlider-text h2 {
        /* font-size: 22px !important; */
    }

    .CaseStudySlider-text p {
        /* font-size: 16px !important; */
        /* margin-top: 10px; */
    }

    .CaseStudySlider-test-2 {
        border-radius: 30px 10px;
        border: 1px solid var(--med, #03A6CA);
        background: #FFF;
        box-shadow: 0px 4px 0px 0px #03A6CA;
        width: 100%;
        /* min-height: 105vw; */
        height: auto;
        padding: 20px 30px 0px;
        /* display: flex !important; */
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }

    .test-main {
        padding-bottom: 100px;
    }

    .NewSlider-Btn {
        top: unset;
    }

    .CaseStudySlider-test-2 span {
        /* font-size: 3.5vw; */
    }
    .test-btn{
        padding-right: 10% !important;
        margin-top: 0;
        position: absolute;
        bottom: 10px;
        right: 0;
    }
}