.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: #bfebfa;
  min-width: 30px;
  min-height: 30px;
  opacity: 1;
  font-size: 0px;
  cursor: pointer;
}
/* .react-multiple-carousel__arrow--right {
  right: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px);
} */

.react-multiple-carousel__arrow :hover {
  background: #005082;
}
.react-multi-carousel-item {
  margin: auto;
}

/* Our testimonial css start from here */
.our-test-wrapper-000 {
  position: relative;
  min-height: 700px;
  height: 100%;
}
.our-test-wrapper-0-1 {
  background-color: #005082;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: -93px;
  padding: 100px 0px;
  /* background-image: url("../../../../images/SubPages/OurTestimonials/MicrosoftTeams-image.webp"); */
}

.our-test-wrapper-0 {
  position: absolute;
  background-color: #bfebfa;
  display: flex;
  left: 0px;
  right: 0px;
  top: 50px;
  bottom: 50px;
  align-items: center;
  box-shadow: 0px 0px 20px 6px rgb(157 157 157 / 52%);
}

.carousel-container12 {
  margin-left: -144px;
}

.our-test-items-wrapper-0 {
  background-color: white;
  /* padding: 40px 45px; */
  padding-left: 45px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 2px transparent;
  /* width: 49%; */
  margin: 0 10px;
}

.our-test-items-wrapper-0-0 {
  width: 60px;
  /* height: 64px; */
}
.our-test-items-wrapper-0-0 img {
  height: 60px;
  /* border-radius: 74px; */
}
/* Popup Css start from here */
.our-test-wrapper-0 div img {
  position: relative;
  z-index: 1;
  width: 70%;
}
/* .our-test-heading-t-0:hover ::before {
  z-index: 5;
} */

.our-test-heading-t-0 div:before {
  /* background-image: url("../../images/MeetOurTeams/MEETOURTEAM.svg"); */
  background-image: url("../../../../images/SubPages/OurTestimonials/playicon.webp");
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center center;
  /* cursor: pointer; */
  content: "";
  left: 0;
  bottom: 0;
  position: absolute;
  visibility: visible;
  z-index: 5;
  /* opacity: 1; */
  height: 100%;
  width: 100%;
  /* max-width: 925px; */
  /* max-height: 450px; */
}

.SaveHome-Meeto-Corosulpopup {
  position: fixed;
  top: 0;
  background: rgba(239, 239, 240, 0.8);
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
}

.close-btn {
  color: #004299;
  font-size: 20px;
  float: right;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 20px;
}

.SaveHome-Meeto-imageBtm {
  bottom: 0%;
  right: 0%;
  left: 0%;
  background: transparent;
  z-index: 99999;
}

.SaveHome-Meeto-Corosulpopupopacity {
  opacity: 1;
}
/* riya css */
.our-test-heading-t-0 :hover img::after {
  opacity: 1;
}
/* Popup Css end here */

/* Our testimonial css End here */

@media only screen and (max-width: 1400px) {
  .our-test-wrapper-0-1 {
    right: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .our-test-wrapper-0-1 {
    /* position: relative; */
    display: block;
    position: relative;
    /* z-index: -444; */
  }
  .our-test-heading-t-0 {
    text-align: center !important;
    margin: auto;
    /* z-index: 110; */
  }
  .our-test-heading-t-0 img {
    margin: auto;
    /* z-index: 110; */

    width: 40% !important;
    margin-top: 55px;
    margin-bottom: 15px;
  }
  .our-test-heading-t-0 div:before {
    width: 100%;
    margin: auto;
    background-position: center center;
  }
  .our-test-wrapper-0 {
    display: block;
  }
  .carousel-container12 {
    margin-left: 0px !important;
  }
  .testimonials-wrapper {
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 567px) {
  .our-test-heading-t-0 img {
    margin: auto;
    /* z-index: 110; */
    width: 60% !important;
    margin-top: 55px;
  }
  .our-test-heading-t-0 div:before {
    width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;

    background-size: 60px;
    margin-top: 30px;

    background-position: center center;
  }
  .our-test-wrapper-0 {
    display: block;
  }

  /* Max 576px */
  .our-test-wrapper-0-1 {
    right: 0px;
    padding: 7px 0px;
  }
  .our-test-wrapper-0 {
    /* left: 25px;
    right: 25px;
    top: 30px;
    bottom: 30px; */
  }
  .our-test-heading-t-0 {
    padding: 0px !important;
    margin: 0px !important;
    text-align: center;
  }
  .our-test-heading-0 {
    padding: 0px;
    color: #005082;
  }
  .our-test-items-wrapper-0 {
    padding-left: 15px;
    padding-right: 00px;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .our-test-items-wrapper-0 div p {
    font-size: 12px !important;
  }
  .carousel-container12 {
    /* background-color: red; */
    margin-left: 0px !important;
  }
  .testimonials-wrapper {
    padding-bottom: 0px;
  }
}
